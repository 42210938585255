<template>
  <v-form class="mx-4">
    <v-container>
      <v-timeline class="sortable_container" dense>
        <MeasuringPoint
          v-for="(mp, index) in measuringPointsWithoutFinish"
          :key="mp.id"
          :ref="`mp-${mp.id}`"
          :position="index"
          :length="form.measuringPoints.length"
          :data="mp"
          :setupSpeedUnit="form.speedUnit"
          :tolerancesEnabled="form.useTolerances"
          :validations="$v.form.measuringPoints.$each.$iter[index]"
          :speedEvent="form.speedEvent"
          @addedDevice="addDeviceToMeasuringPoint"
          @removedDevice="removeDeviceFromMeasuringPoint"
          @removed="removeMeasuringPoint"
          @channelChanged="changeChannelOfDevice"
          @toleranceUpdated="changeToleranceOfMeasuringPoint"
          @speedMeasurementUpdated="changeSpeedMeasurementOfMeasuringPoint"
          @speedUnitUpdated="changeSpeedUnit"
        />

        <v-timeline-item hide-dot>
          <v-btn fab icon tile elevation="2" @click="addMeasuringPoint">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-timeline-item>

        <MeasuringPoint
          v-if="finish"
          :key="finish.id"
          :ref="`mp-${finish.id}`"
          :position="form.measuringPoints.length - 1"
          :length="form.measuringPoints.length"
          :data="finish"
          :setupSpeedUnit="form.speedUnit"
          :tolerancesEnabled="form.useTolerances"
          :validations="
            $v.form.measuringPoints.$each.$iter[form.measuringPoints.length - 1]
          "
          :speedEvent="form.speedEvent"
          @addedDevice="addDeviceToMeasuringPoint"
          @removedDevice="removeDeviceFromMeasuringPoint"
          @channelChanged="changeChannelOfDevice"
          @toleranceUpdated="changeToleranceOfMeasuringPoint"
          @speedMeasurementUpdated="changeSpeedMeasurementOfMeasuringPoint"
          @speedUnitUpdated="changeSpeedUnit"
        />
      </v-timeline>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-label>{{ $t("forms.event.runTime") }}</v-label>
        </v-col>
        <v-col cols="6">
          <v-select
            :label="$t('forms.event.precision')"
            :items="precisions"
            v-model="form.precision"
            @change="$v.form.$touch"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            :label="$t('forms.event.roundingType')"
            :items="roundings"
            :item-text="item => $t(item.text)"
            v-model="form.rounding"
            @change="$v.form.$touch"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="hasAnySpeed">
        <v-col cols="12">
          <v-label>{{ $t("forms.event.speed") }}</v-label>
        </v-col>
        <v-col cols="6">
          <v-select
            :label="$t('forms.event.precision')"
            :items="speedPrecisions"
            :item-text="el => `${el.text} ${speedUnitLabel}`"
            v-model="form.speedPrecision"
            @change="$v.form.$touch"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            :label="$t('forms.event.roundingType')"
            :items="roundings"
            :item-text="item => $t(item.text)"
            v-model="form.speedRounding"
            @change="$v.form.$touch"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-label>{{ $t("forms.event.runningTime") }}</v-label>
          <v-switch
            v-model="form.runningTime"
            @change="$v.form.$touch"
          ></v-switch>
        </v-col>
        <v-col cols="12">
          <v-label>{{ $t("forms.event.referenceRun") }}</v-label>
          <v-switch
            v-model="form.useTolerances"
            @change="$v.form.$touch"
            :disabled="form.speedEvent"
          ></v-switch>
        </v-col>
        <v-col v-if="measuringPointsCount === 2" cols="12">
          <v-label>{{ $t("forms.event.speedEvent") }}</v-label>
          <v-switch
            v-model="form.speedEvent"
            @change="$v.form.$touch"
          ></v-switch>
        </v-col>
        <v-col cols="12">
          <div class="d-flex">
            <v-label>{{ $t("forms.event.massStart") }}</v-label>
            <v-tooltip top max-width="400">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-1" small
                  >mdi-alert-circle-outline</v-icon
                >
              </template>
              {{ $t("forms.event.hints.massStart") }}
            </v-tooltip>
          </div>
          <v-select
            v-model="form.massStartFieldId"
            :items="massStartFieldIdItems"
          />
        </v-col>
        <v-col cols="12">
          <div class="d-flex mb-4">
            <v-label>{{ $t("forms.event.regularity.label") }}</v-label>
            <v-tooltip top max-width="400">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-1" small
                  >mdi-alert-circle-outline</v-icon
                >
              </template>
              {{ $t("forms.event.hints.regularity") }}
            </v-tooltip>
          </div>
          <v-btn-toggle
            v-model="form.regularity"
            mandatory
            dense
            active-class="primary--text"
          >
            <v-btn
              v-for="regularityType in regularityTypes"
              :key="regularityType.value"
              :value="regularityType.value"
            >
              {{ $t(regularityType.text) }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
/* eslint-disable */

import { Sortable } from "@shopify/draggable/lib/es5/draggable.bundle.legacy";
import Vue from "vue";
import cuid from "cuid";
import MeasuringPoint from "@/components/MeasuringPoint";
import deviceTypes from "@/utils/deviceTypes";
import { requiredIf, numeric } from "vuelidate/lib/validators";
import whitelist from "@/mixins/whitelist";

export default {
  name: "FormSetup",
  props: {
    wizardData: {
      type: Object,
      required: true
    },
    currentStepNumber: {
      type: Number,
      default: null
    }
  },
  components: {
    MeasuringPoint
  },
  data() {
    return {
      stepNumber: 5,
      form: {
        precision: "1/100s",
        rounding: "CUT_OFF",
        speedPrecision: "1/100u",
        speedRounding: "CUT_OFF",
        runningTime: true,
        useTolerances: false,
        measuringPoints: [],
        speedEvent: false,
        speedUnit: "KM_H",
        massStartFieldId: null,
        regularity: "DISABLED"
      },
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"],
      speedPrecisions: [
        {
          text: "1",
          value: "1u"
        },
        {
          text: "1/10",
          value: "1/10u"
        },
        {
          text: "1/100",
          value: "1/100u"
        },
        {
          text: "1/1000",
          value: "1/1000u"
        },
        {
          text: "1/10000",
          value: "1/10000u"
        },
        {
          text: "1/100000",
          value: "1/100000u"
        }
      ],
      roundings: [
        {
          text: "forms.event.roundings.cutOff",
          value: "CUT_OFF"
        },
        {
          text: "forms.event.roundings.roundUp",
          value: "ROUND_UP"
        },
        {
          text: "forms.event.roundings.round",
          value: "ROUND"
        }
      ],
      regularityTypes: [
        {
          text: "forms.event.regularity.disabled",
          value: "DISABLED"
        },
        {
          text: "forms.event.regularity.targetTime",
          value: "TARGET_TIME"
        },
        {
          text: "forms.event.regularity.perfection",
          value: "PERFECTION"
        }
      ]
    };
  },
  validations() {
    return {
      form: {
        measuringPoints: {
          $each: {
            toleranceLowerBound: {
              required: requiredIf(function(obj) {
                const index = this.form.measuringPoints.findIndex(
                  el => el.id === obj.id
                );

                if (index !== -1) {
                  return this.form.useTolerances && index !== 0;
                }

                return false;
              }),
              numeric
            },
            toleranceUpperBound: {
              required: requiredIf(function(obj) {
                const index = this.form.measuringPoints.findIndex(
                  el => el.id === obj.id
                );

                if (index !== -1) {
                  return this.form.useTolerances && index !== 0;
                }

                return false;
              }),
              numeric
            },
            speedMeasurementDistance: {
              required: requiredIf(function(obj) {
                const index = this.form.measuringPoints.findIndex(
                  el => el.id === obj.id
                );

                if (index !== -1) {
                  return obj.speedMeasurementEnabled && index !== 0;
                }

                return false;
              }),
              maxDecimals: function(value, obj) {
                return obj.speedMeasurementEnabled
                  ? new RegExp(`^\\d+((\\.|\\,)\\d{0,2})?$`).test(value)
                  : true;
              }
            },
            speedMeasurementMinSpeed: {
              required: requiredIf(function(obj) {
                const index = this.form.measuringPoints.findIndex(
                  el => el.id === obj.id
                );

                if (index !== -1) {
                  return obj.speedMeasurementEnabled && index !== 0;
                }

                return false;
              }),
              maxDecimals: function(value, obj) {
                return obj.speedMeasurementEnabled
                  ? new RegExp(`^\\d+((\\.|\\,)\\d{0,1})?$`).test(value)
                  : true;
              }
            },
            speedMeasurementMaxSpeed: {
              required: requiredIf(function(obj) {
                const index = this.form.measuringPoints.findIndex(
                  el => el.id === obj.id
                );

                if (index !== -1) {
                  return obj.speedMeasurementEnabled && index !== 0;
                }

                return false;
              }),
              maxDecimals: function(value, obj) {
                return obj.speedMeasurementEnabled
                  ? new RegExp(`^\\d+((\\.|\\,)\\d{0,1})?$`).test(value)
                  : true;
              }
            }
          }
        }
      }
    };
  },
  watch: {
    currentStepNumber(newCurrentStepNumber) {
      if (newCurrentStepNumber && newCurrentStepNumber === this.stepNumber) {
        this.assignData();
      }
    },
    measuringPointsCount(newMeasuringPointsCount) {
      if (newMeasuringPointsCount !== 2) {
        this.form.speedEvent = false;
      }
    },
    formSpeedEvent(newFormSpeedEvent) {
      if (newFormSpeedEvent) {
        this.form.useTolerances = false;
        this.form.measuringPoints[1].speedMeasurementEnabled = true;
      }
    },
    massStartFieldIdItems(newMassStartFieldIdItems) {
      if (this.form.massStartFieldId) {
        const selectedMassStartFieldIdIndex = newMassStartFieldIdItems.findIndex(
          el => el.value === this.form.massStartFieldId
        );
        if (selectedMassStartFieldIdIndex === -1) {
          this.form.massStartFieldId = null;
        }
      }
    }
  },
  computed: {
    measuringPointsCount() {
      return this.form.measuringPoints.length;
    },
    formSpeedEvent() {
      return this.form.speedEvent;
    },
    measuringPointsWithoutFinish() {
      return this.form.measuringPoints.slice(0, -1);
    },
    finish() {
      return this.form.measuringPoints.slice(-1)[0];
    },
    massStartFieldIdItems() {
      const blacklist = [
        "startNumber",
        "firstName",
        "lastName",
        "dateOfBirth",
        "email"
      ];
      const fieldIds = this.wizardData.registration.data
        .filter(el => !blacklist.includes(el.id))
        .map(el => ({
          value: el.id,
          text: this.whitelist.includes(el.id)
            ? this.$i18n.t(`competitors.tables.${el.id}`)
            : el.view.label
        }));
      return [
        { value: "ALL", text: this.$i18n.t("forms.event.all") },
        { divider: true },
        ...fieldIds,
        { divider: true },
        { value: null, text: this.$i18n.t("forms.event.disabled") }
      ];
    },
    hasAnySpeed() {
      return (
        this.form.measuringPoints.filter(el => el.speedMeasurementEnabled)
          .length > 0
      );
    },
    speedUnitLabel() {
      switch (this.form.speedUnit) {
        case "MP_H":
          return "mph";
        case "KM_H":
          return "km/h";
        case "M_S":
          return "m/s";
        default:
          return "u";
      }
    }
  },
  mixins: [whitelist],
  methods: {
    assignData() {
      Object.assign(this.form, this.wizardData.timingSetup);
    },
    submit() {
      this.$v.$touch();

      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve({
            step: "timingSetup",
            form: this.form
          });
        } else {
          reject({
            step: "timingSetup",
            form: this.form
          });
        }
      });
    },
    addMeasuringPoint() {
      this.form.measuringPoints.splice(-1, 0, {
        id: cuid(),
        devices: [],
        toleranceLowerBound: 10,
        toleranceIsLowerPercentage: true,
        toleranceUpperBound: 20,
        toleranceIsUpperPercentage: true,
        speedMeasurementEnabled: false,
        speedMeasurementDistance: 5,
        speedMeasurementMinSpeed: 10,
        speedMeasurementMaxSpeed: 150
      });

      this.$nextTick(() => this.$v.$touch);
    },
    removeMeasuringPoint(measuringPointId) {
      const measuringPointIndex = this.form.measuringPoints.findIndex(
        el => el.id === measuringPointId
      );
      this.form.measuringPoints.splice(measuringPointIndex, 1);

      this.$nextTick(() => this.$v.$touch);
    },
    moveItem(items, oldIndex, newIndex) {
      const itemRemovedArray = [
        ...items.slice(0, oldIndex),
        ...items.slice(oldIndex + 1, items.length)
      ];
      return [
        ...itemRemovedArray.slice(0, newIndex),
        items[oldIndex],
        ...itemRemovedArray.slice(newIndex, itemRemovedArray.length)
      ];
    },
    addDeviceToMeasuringPoint(measuringPointId, deviceId) {
      this.$v.form.$touch();

      const measuringPointIndex = this.form.measuringPoints.findIndex(
        el => el.id === measuringPointId
      );

      this.form.measuringPoints[measuringPointIndex].devices.push({
        deviceId,
        channelIndex:
          measuringPointIndex === this.form.measuringPoints.length - 1 ? 1 : 0
      });
    },
    removeDeviceFromMeasuringPoint(measuringPointId, deviceIndex) {
      this.$v.form.$touch();

      const measuringPointIndex = this.form.measuringPoints.findIndex(
        el => el.id === measuringPointId
      );

      this.form.measuringPoints[measuringPointIndex].devices.splice(
        deviceIndex,
        1
      );
    },
    changeChannelOfDevice(measuringPointId, item, itemIndex) {
      this.$v.form.$touch();

      const measuringPointIndex = this.form.measuringPoints.findIndex(
        el => el.id === measuringPointId
      );
      const currentChannelIndex = this.form.measuringPoints[measuringPointIndex]
        .devices[itemIndex].channelIndex;
      const newChannelIndex =
        (currentChannelIndex + 1) %
        deviceTypes[item.device.type].channels.length;

      Vue.set(
        this.form.measuringPoints[measuringPointIndex].devices[itemIndex],
        "channelIndex",
        newChannelIndex
      );
    },
    changeToleranceOfMeasuringPoint(measuringPointId, tolerance) {
      this.$v.form.$touch();

      const measuringPointIndex = this.form.measuringPoints.findIndex(
        el => el.id === measuringPointId
      );

      Vue.set(
        this.form.measuringPoints[measuringPointIndex],
        "tolerance",
        tolerance
      );
    },
    changeSpeedMeasurementOfMeasuringPoint(measuringPointId, speedMeasurement) {
      this.$v.form.$touch();

      const measuringPointIndex = this.form.measuringPoints.findIndex(
        el => el.id === measuringPointId
      );

      Vue.set(
        this.form.measuringPoints[measuringPointIndex],
        "speedMeasurement",
        speedMeasurement
      );
    },
    changeSpeedUnit(speedUnit) {
      this.$v.form.$touch();

      this.form.speedUnit = speedUnit;
    }
  },
  created() {
    this.assignData();
  },
  mounted() {
    const containerSelector = ".sortable_container";
    const containers = document.querySelectorAll(containerSelector);

    if (containers.length === 0) {
      return false;
    }

    const sortable = new Sortable(containers, {
      draggable: ".sortable_item",
      mirror: {
        constrainDimensions: true
      },
      delay: 200
    });

    sortable.on("sortable:stop", ({ oldIndex, newIndex }) => {
      const measuringPoints = this.moveItem(
        this.form.measuringPoints,
        oldIndex + 1,
        newIndex + 1
      );
      this.form.measuringPoints = measuringPoints;

      this.$nextTick(() => this.$v.$touch);
    });
  },
  activated() {
    this.assignData();
  }
};
</script>

<style scoped>
.sortable_container:focus,
.sortable_item:focus {
  outline: none;
}
.draggable-source--is-dragging {
  opacity: 0.5;
}
</style>
