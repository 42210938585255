<template>
  <v-form class="mx-4">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            :label="$t('forms.event.organizerName')"
            name="name"
            v-model="$v.form.name.$model"
            :error-messages="nameErrors"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            :label="$t('forms.event.organizerWebsite')"
            name="website"
            placeholder="https://example.com"
            v-model="$v.form.website.$model"
            :error-messages="websiteErrors"
            @blur="checkUrl"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-file-input
            :label="$t('forms.event.organizerImage')"
            name="image"
            v-model="form.image.file"
            :loading="form.image.loading"
            prepend-icon="mdi-image"
            accept="image/*"
            @change="fileInputHandler"
            show-size
            :hint="
              $t('forms.event.hints.organizerImage', {
                height: 100,
                width: 600
              })
            "
            persistent-hint
          ></v-file-input>
        </v-col>

        <v-col cols="12">
          <div class="ml-md-8" style="overflow: auto">
            <canvas
              id="organizerImagePreview"
              class="elevation-2"
              height="0"
              width="0"
            ></canvas>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
/* eslint-disable */

import { required, url, maxLength } from "vuelidate/lib/validators";
import Url from "url-parse";

export default {
  name: "FormOrganizer",
  props: {
    event: {
      type: Object,
      default: null
    },
    wizardData: {
      type: Object,
      required: true
    },
    currentStepNumber: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      stepNumber: 2,
      form: {
        name: null,
        website: null,
        image: {
          file: null,
          data: null,
          loading: false
        }
      },
      baseURL: process.env.VUE_APP_BASE_URL
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(510)
        },
        website: {
          url,
          maxLength: maxLength(2040)
        }
      }
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push(this.$i18n.t("forms.event.errors.organizerName.required"));
      !this.$v.form.name.maxLength && errors.push(this.$i18n.t("forms.event.errors.organizerName.maxLength", { length: 510 }));
      return errors;
    },
    websiteErrors() {
      const errors = [];
      if (!this.$v.form.website.$dirty) return errors;
      !this.$v.form.website.url && errors.push(this.$i18n.t("forms.event.errors.organizerWebsite.format"));
      !this.$v.form.website.maxLength && errors.push(this.$i18n.t("forms.event.errors.organizerWebsite.maxLength", { length: 2040 }));
      return errors;
    }
  },
  watch: {
    currentStepNumber(newCurrentStepNumber, oldCurrentStepNumber) {
      if (newCurrentStepNumber && newCurrentStepNumber === this.stepNumber) {
        this.assignData();
      }
    }
  },
  methods: {
    checkUrl(event) {
      const value = event.target.value;

      if (value !== "") {
        const parsed = Url(value, {});
        const prefix = "http://";

        if (parsed.protocol === "") {
          this.form.website = `${prefix}${this.form.website}`;
        }
      }
    },
    fileInputHandler(file) {
      this.$v.form.$touch();
      this.form.image.loading = true;

      if (file) {
        this.getFileFromInput(file);
      } else {
        this.clearCanvas("organizerImagePreview");
        this.form.image.loading = false;
        this.form.image.data = null;
      }
    },
    getFileFromInput(file) {
      const self = this;
      const image = new Image();
      const reader = new FileReader();

      reader.onload = function(e) {
        image.src = e.target.result;
      }

      reader.onerror = function(event) {
        self.form.image.loading = false;
        console.error("File could not be read! Code " + event.target.error.code);
      };

      image.onload = function() {
        self.setCanvas("organizerImagePreview", image, 100, 600);
      }

      reader.readAsDataURL(file);
    },
    getFileFromURL(imageUrl) {
      this.form.image.loading = true;
      
      const self = this;
      const req = new XMLHttpRequest();
      
      req.open("GET", `${this.baseURL}/${imageUrl}`, true);
      req.responseType = "blob";
      req.onload = function() {
        const blob = req.response;
        blob.name = "organizer.jpg";
        createImageBitmap(blob)
          .then(image => {
            self.form.image.file = blob;
            self.setCanvas("organizerImagePreview", image, 100, 600);
          });
      };
      req.send();
    },
    setCanvas(canvasId, image, maxHeight = 300, maxWidth = 300) {
      const canvas = document.getElementById(canvasId);
      const context = canvas.getContext("2d");

      let imageHeight = image.height;
      let imageWidth = image.width;

      if (imageHeight > maxHeight) {
        const ratio = maxHeight / imageHeight;
        imageHeight = imageHeight * ratio;
        imageWidth = imageWidth * ratio;
      }

      if (imageWidth > maxWidth) {
        const ratio = maxWidth / imageWidth;
        imageHeight = imageHeight * ratio;
        imageWidth = imageWidth * ratio;
      }

      canvas.height = imageHeight;
      canvas.width = imageWidth;

      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, imageWidth, imageHeight);

      this.form.image.data = canvas.toDataURL("image/jpeg", 1.0);
      this.form.image.loading = false;
    },
    clearCanvas(id) {
      const canvas = document.getElementById(id);
      const context = canvas.getContext("2d");

      context.clearRect(0, 0, canvas.width, canvas.height);
      canvas.height = 0;
      canvas.width = 0;
    },
    assignData() {
      Object.assign(this.form, this.wizardData.organizer);
    },
    submit() {
      this.$v.$touch();
      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve({
            step: "organizer",
            form: this.form
          });
        } else {
          reject({
            step: "organizer",
            form: this.form
          });
        }
      });
    }
  },
  created() {
    this.assignData();

    if (this.event && this.event.organizer.imageUrl) {
      this.getFileFromURL(this.event.organizer.imageUrl);
    }
  },
  activated() {
    this.assignData();
  }
};
</script>
