var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"mx-4"},[_c('v-container',[(_vm.numberORatedfHeats > 1)?_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pb-4"},[_c('v-card-text',{staticClass:"d-flex align-end"},[_c('v-checkbox',{staticClass:"mr-4",attrs:{"label":_vm.$t('forms.event.bestOfRuns'),"hide-details":""},model:{value:(_vm.form.rankingBestOf.enabled),callback:function ($$v) {_vm.$set(_vm.form.rankingBestOf, "enabled", $$v)},expression:"form.rankingBestOf.enabled"}}),_c('v-slider',{attrs:{"min":"1","max":_vm.numberORatedfHeats,"ticks":"always","tick-size":"4","tick-labels":_vm.heatTicks,"disabled":!_vm.form.rankingBestOf.enabled,"hide-details":""},model:{value:(_vm.form.rankingBestOf.count),callback:function ($$v) {_vm.$set(_vm.form.rankingBestOf, "count", $$v)},expression:"form.rankingBestOf.count"}})],1)],1)],1)],1):_vm._e(),_c('v-row',[(!_vm.$v.form.dates.required)?_c('v-card-subtitle',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t("forms.event.chooseDate"))+" ")]):_vm._e(),_c('v-col',{attrs:{"sm":"12"}},_vm._l((_vm.form.dates),function(date,dateIndex){return _c('v-card',{key:`date-${dateIndex}`,staticClass:"mb-2",attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$d(date.date, "shortDate"))+" ")]),(_vm.dateError(dateIndex))?_c('v-card-subtitle',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.dateError(dateIndex))+" ")]):_vm._e(),_c('v-card-text',{staticClass:"py-0"},_vm._l((_vm.form.dates[dateIndex].items),function(heat,heatIndex){return _c('v-row',{key:`heat-${heatIndex}`},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('forms.event.heatName', { number: heatIndex + 1 }),"error-messages":_vm.nameErrors(dateIndex, heatIndex)},model:{value:(
                    _vm.$v.form.dates.$each.$iter[dateIndex].items.$each.$iter[
                      heatIndex
                    ].name.$model
                  ),callback:function ($$v) {_vm.$set(_vm.$v.form.dates.$each.$iter[dateIndex].items.$each.$iter[
                      heatIndex
                    ].name, "$model", $$v)},expression:"\n                    $v.form.dates.$each.$iter[dateIndex].items.$each.$iter[\n                      heatIndex\n                    ].name.$model\n                  "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('forms.event.heatStartTime', {
                          number: heatIndex + 1
                        }),"value":_vm.$d(
                          _vm.form.dates[dateIndex].items[heatIndex].startTime,
                          'time'
                        ),"prepend-icon":"mdi-clock-outline","readonly":""},on:{"click:prepend":function($event){_vm.form.dates[dateIndex].items[
                          heatIndex
                        ].showTimePicker = true}}},on))]}}],null,true),model:{value:(
                    _vm.form.dates[dateIndex].items[heatIndex].showTimePicker
                  ),callback:function ($$v) {_vm.$set(_vm.form.dates[dateIndex].items[heatIndex], "showTimePicker", $$v)},expression:"\n                    form.dates[dateIndex].items[heatIndex].showTimePicker\n                  "}},[(
                      _vm.form.dates[dateIndex].items[heatIndex].showTimePicker
                    )?_c('v-time-picker',{attrs:{"value":_vm._f("moment")(_vm.form.dates[dateIndex].items[heatIndex].startTime,'HH:mm'),"format":"24hr","full-width":""},on:{"input":value =>
                        _vm.startTimePickerHandler(value, dateIndex, heatIndex),"click:minute":function($event){_vm.form.dates[dateIndex].items[
                        heatIndex
                      ].showTimePicker = false}}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("forms.event.isRated"))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"small":"","right":""}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("forms.event.hints.rated"))+" ")])]},proxy:true}],null,true),model:{value:(
                    _vm.$v.form.dates.$each.$iter[dateIndex].items.$each.$iter[
                      heatIndex
                    ].isRated.$model
                  ),callback:function ($$v) {_vm.$set(_vm.$v.form.dates.$each.$iter[dateIndex].items.$each.$iter[
                      heatIndex
                    ].isRated, "$model", $$v)},expression:"\n                    $v.form.dates.$each.$iter[dateIndex].items.$each.$iter[\n                      heatIndex\n                    ].isRated.$model\n                  "}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeHeatFromDate(dateIndex, heatIndex)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.addHeatToDate(dateIndex)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-plus-circle")])],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }