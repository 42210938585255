<template>
  <v-card :loading="formLoading">
    <v-card-title>
      <span class="pr-1">{{ `${$t("events.editEvent")}:` }}</span
      ><router-link
        v-if="event"
        :to="{ name: 'Live', params: { id: event.id } }"
        >{{ event.name }}</router-link
      >
    </v-card-title>

    <div v-if="!formLoading">
      <v-chip-group
        v-model="currentStepNumber"
        mandatory
        center-active
        show-arrows
        active-class="primary"
        class="px-sm-7"
      >
        <v-chip
          v-for="step in steps"
          :key="step.id"
          :disabled="step.disabled"
          :color="step.error === null ? '' : 'error'"
          outlined
        >
          {{ $t(step.name) }}
        </v-chip>
      </v-chip-group>

      <component
        v-for="(step, index) in steps"
        :key="`form-${step.id}`"
        :ref="`form-${step.id}`"
        :is="step.form"
        v-show="index === currentStepNumber"
        :event="event"
        :wizard-data="form"
        :editing="true"
        @dateChanged="calculateDates"
      ></component>

      <v-alert
        v-if="currentStepError"
        class="mt-4 mb-8 mx-4"
        dense
        outlined
        type="error"
      >
        {{ currentStepError }}
      </v-alert>
      <v-alert
        v-else-if="currentStepSuccess"
        class="mt-4 mb-8 mx-4"
        dense
        outlined
        type="success"
      >
        {{ $t(currentStepSuccess) }}
      </v-alert>
    </div>

    <div class="d-flex justify-space-between pa-4">
      <v-btn @click="backButtonAction" :disabled="actionLoading">
        {{ $t("forms.event.cancel") }}
      </v-btn>

      <v-btn
        @click="submitButtonAction"
        color="primary"
        :loading="actionLoading"
        :disabled="actionLoading"
      >
        {{ $t("forms.event.submit") }}
      </v-btn>
    </div>

    <v-progress-linear
      v-show="actionLoading"
      :value="progress"
      rounded
    ></v-progress-linear>

    <Confirmation ref="dialog">
      <v-card-text>
        {{ $t("forms.confirmation.saveText") }}
      </v-card-text>

      <v-card-text>
        {{ $t("forms.event.dirtyForms") }}
        <ul>
          <li v-for="form in dirtyForms" :key="form.id">
            {{ $t(form.name) }}
          </li>
        </ul>
      </v-card-text>
    </Confirmation>
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapActions } from "vuex";
import cuid from "cuid";
import moment, { invalid } from "moment";
import FormGeneral from "./forms/event/FormGeneral";
import FormOrganizer from "./forms/event/FormOrganizer";
import FormHeats from "./forms/event/FormHeats";
import FormRegistration from "./forms/event/FormRegistration";
import FormSetup from "./forms/event/FormSetup";
import Confirmation from "@/components/Confirmation";
import deviceTypes from "@/utils/deviceTypes";

export default {
  name: "PanelEventsEdit",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    FormGeneral,
    FormOrganizer,
    FormHeats,
    FormRegistration,
    FormSetup,
    Confirmation
  },
  data() {
    return {
      form: {
        event: {
          name: null,
          place: null,
          country: null,
          date: {
            multiDay: false,
            from: {
              showDatePicker: false,
              timestamp: null
            },
            to: {
              showDatePicker: false,
              timestamp: null
            }
          },
          image: {
            file: null,
            loading: false
          },
          showDatePicker: false,
          publicResult: true,
          registration: {
            public: false,
            from: {
              showDatePicker: false,
              showTimePicker: false,
              timestamp: null
            },
            to: {
              showDatePicker: false,
              showTimePicker: false,
              timestamp: null
            }
          }
        },
        heats: {
          rankingBestOf: {
            enabled: false,
            count: 0
          },
          dates: []
        },
        organizer: {
          name: null,
          website: null,
          image: {
            data: null,
            loading: false
          }
        },
        registration: {
          data: null
        },
        timingSetup: {
          precision: "1/100s",
          rounding: "CUT_OFF",
          speedPrecision: "1/100u",
          speedRounding: "CUT_OFF",
          runningTime: true,
          speedEvent: false,
          measuringPoints: [],
          massStartFieldId: null,
          regularity: "DISABLED"
        }
      },
      progress: 0,
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"],
      speedPrecisions: [
        "1u",
        "1/10u",
        "1/100u",
        "1/1000u",
        "1/10000u",
        "1/100000u"
      ],
      steps: [
        {
          id: "event",
          name: "forms.event.event",
          form: "FormGeneral",
          disabled: false,
          error: null,
          success: null
        },
        {
          id: "organizer",
          name: "forms.event.organizer",
          form: "FormOrganizer",
          disabled: false,
          error: null,
          success: null
        },
        {
          id: "heats",
          name: "forms.event.heats",
          form: "FormHeats",
          disabled: false,
          error: null,
          success: null
        },
        {
          id: "registration",
          name: "forms.event.registration",
          form: "FormRegistration",
          disabled: false,
          error: null,
          success: null
        },
        {
          id: "timingSetup",
          name: "forms.event.timingSetup",
          form: "FormSetup",
          disabled: false,
          error: null,
          success: null
        }
      ],
      currentStepNumber: 0,
      formLoading: true,
      actionLoading: false,
      error: null,
      success: null,
      dirtyForms: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      event: state => state.events.selectedItemByUser
    }),
    ...mapGetters({
      userIsAdmin: "user/isAdmin"
    }),
    userIsOwner() {
      if (this.user && this.event) {
        return this.user.id === this.event.ownerId;
      }

      return false;
    },
    currentStepSuccess() {
      return this.steps[Number(this.currentStepNumber)].success;
    },
    currentStepError() {
      return this.steps[Number(this.currentStepNumber)].error;
    },
    formDataEventDate() {
      return this.form.event.date;
    }
  },
  methods: {
    ...mapActions({
      fetchDevices: "devices/fetchItems",
      fetchSelectedEvent: "events/fetchSelectedItemByUser",
      updateItem: "events/updateItem",
      updateEventSettingsRankBestOfCount:
        "events/updateEventSettingsRankBestOfCount",
      updateEventSettingsMassStart: "events/updateEventSettingsMassStart"
    }),
    updateEvent() {
      return new Promise((resolve, reject) => {
        this.steps[0].success = null;
        this.steps[0].error = null;

        this.$refs["form-event"][0]
          .submit()
          .then(stepData => {
            Object.assign(this.form.event, stepData.form);

            const payload = {
              step: "event",
              userId: this.user.id,
              eventId: this.event.id,
              data: {
                country: this.form.event.country,
                date: {
                  multiday: this.form.event.date.multiDay,
                  from: moment(this.form.event.date.from.timestamp).format(
                    "YYYY-MM-DD"
                  ),
                  to: moment(this.form.event.date.to.timestamp).format(
                    "YYYY-MM-DD"
                  )
                },
                image: this.form.event.image.data,
                name: this.form.event.name,
                place: this.form.event.place,
                publicResult: this.form.event.publicResult,
                registration: {
                  public: this.form.event.registration.public,
                  from: new Date(
                    this.form.event.registration.from.timestamp
                  ).toISOString(),
                  to: new Date(
                    this.form.event.registration.to.timestamp
                  ).toISOString()
                }
              }
            };

            this.updateItem(payload)
              .then(response => {
                this.steps[0].success = "forms.event.updateSuccess";
                this.$refs["form-event"][0].$v.$reset();
                resolve();
              })
              .catch(response => {
                switch (response.data.status) {
                  case -3021:
                    this.steps[0].error = this.$i18n.t(
                      "forms.event.errors.imageNotSupported"
                    );
                    break;
                  case -3014:
                    this.steps[0].error = this.$i18n.t(
                      "forms.event.errors.updateFailed"
                    );
                    break;
                  default:
                    this.steps[0].error = this.$i18n.t(
                      "errors.unexpectedError"
                    );
                }

                reject();
              });
          })
          .catch(stepData => {
            this.steps[0].error = this.$i18n.t(
              "forms.event.errors.invalidForm"
            );

            reject();
          });
      });
    },
    updateOrganizer() {
      return new Promise((resolve, reject) => {
        this.steps[1].success = null;
        this.steps[1].error = null;

        this.$refs["form-organizer"][0]
          .submit()
          .then(stepData => {
            Object.assign(this.form.organizer, stepData.form);

            const payload = {
              step: "organizer",
              userId: this.user.id,
              eventId: this.event.id,
              data: {
                organizerImage: this.form.organizer.image.data,
                organizerName: this.form.organizer.name,
                organizerWebsite: this.form.organizer.website
              }
            };

            this.updateItem(payload)
              .then(response => {
                this.steps[1].success = "forms.event.updateSuccess";
                this.$refs["form-organizer"][0].$v.$reset();
                resolve();
              })
              .catch(response => {
                switch (response.data.status) {
                  case -3021:
                    this.steps[1].error = this.$i18n.t(
                      "forms.event.errors.imageNotSupported"
                    );
                    break;
                  case -3014:
                    this.steps[1].error = this.$i18n.t(
                      "forms.event.errors.updateFailed"
                    );
                    break;
                  default:
                    this.steps[1].error = this.$i18n.t(
                      "errors.unexpectedError"
                    );
                }

                reject();
              });
          })
          .catch(stepData => {
            this.steps[1].error = this.$i18n.t(
              "forms.event.errors.invalidForm"
            );

            reject();
          });
      });
    },
    updateHeats() {
      return new Promise((resolve, reject) => {
        this.steps[2].success = null;
        this.steps[2].error = null;

        this.$refs["form-heats"][0]
          .submit()
          .then(stepData => {
            Object.assign(this.form.heats, stepData.form);

            const payload = {
              step: "heats",
              userId: this.user.id,
              eventId: this.event.id,
              data: []
            };

            this.form.heats.dates.forEach((date, dateIndex) => {
              date.items.forEach((heat, heatIndex) => {
                payload.data.push({
                  id: heat.id,
                  name: heat.name,
                  timestamp: new Date(heat.startTime).toISOString(),
                  excludeFromOverallRanking: !heat.isRated
                });
              });
            });

            this.updateItem(payload)
              .then(response => {
                this.steps[2].success = "forms.event.updateSuccess";
                this.$refs["form-heats"][0].$v.$reset();
                resolve();
              })
              .catch(response => {
                switch (response.data.status) {
                  case -3014:
                    this.steps[2].error = this.$i18n.t(
                      "forms.event.errors.updateFailed"
                    );
                    break;
                  case -3020:
                    this.steps[2].error = this.$i18n.t(
                      "forms.event.errors.heatUpdateInsuffTPs"
                    );
                    break;
                  default:
                    this.steps[2].error = this.$i18n.t(
                      "errors.unexpectedError"
                    );
                }

                reject();
              });
          })
          .catch(stepData => {
            this.steps[2].error = this.$i18n.t(
              "forms.event.errors.invalidForm"
            );

            reject();
          });
      });
    },
    updateRankingSettings() {
      return new Promise((resolve, reject) => {
        this.steps[2].success = null;
        this.steps[2].error = null;

        this.$refs["form-heats"][0]
          .submit()
          .then(stepData => {
            Object.assign(this.form.heats, stepData.form);

            const payload = {
              eventId: this.event.id,
              data: {
                rankBestOfCount: stepData.form.rankingBestOf.enabled
                  ? stepData.form.rankingBestOf.count
                  : 0
              }
            };

            this.updateEventSettingsRankBestOfCount(payload)
              .then(response => {
                this.steps[2].success = "forms.event.updateSuccess";
                this.$refs["form-heats"][0].$v.$reset();
                resolve();
              })
              .catch(response => {
                switch (response.data.status) {
                  default:
                    this.steps[2].error = this.$i18n.t(
                      "errors.unexpectedError"
                    );
                }

                reject();
              });
          })
          .catch(stepData => {
            this.steps[2].error = this.$i18n.t(
              "forms.event.errors.invalidForm"
            );

            reject();
          });
      });
    },
    updateRegistration() {
      return new Promise((resolve, reject) => {
        this.steps[3].success = null;
        this.steps[3].error = null;

        this.$refs["form-registration"][0]
          .submit()
          .then(stepData => {
            Object.assign(this.form.registration, stepData.form);

            const payload = {
              step: "registration",
              userId: this.user.id,
              eventId: this.event.id,
              data: {
                data: this.form.registration.data
              }
            };

            this.updateItem(payload)
              .then(response => {
                this.steps[3].success = "forms.event.updateSuccess";
                this.$refs["form-registration"][0].$v.$reset();
                resolve();
              })
              .catch(response => {
                switch (response.data.status) {
                  case -3014:
                    this.steps[3].error = this.$i18n.t(
                      "forms.event.errors.updateFailed"
                    );
                    break;
                  default:
                    this.steps[3].error = this.$i18n.t(
                      "errors.unexpectedError"
                    );
                }

                reject();
              });
          })
          .catch(stepData => {
            this.steps[3].error = this.$i18n.t(
              "forms.event.errors.invalidForm"
            );

            reject();
          });
      });
    },
    updateTimingSetup() {
      return new Promise((resolve, reject) => {
        this.steps[4].success = null;
        this.steps[4].error = null;

        this.$refs["form-timingSetup"][0]
          .submit()
          .then(stepData => {
            Object.assign(this.form.timingSetup, stepData.form);

            const payload = {
              step: "timingsetup",
              userId: this.user.id,
              eventId: this.event.id,
              data: {
                precision: this.form.timingSetup.precision,
                rounding: this.form.timingSetup.rounding,
                speedPrecision: this.form.timingSetup.speedPrecision,
                speedRounding: this.form.timingSetup.speedRounding,
                runningTime: this.form.timingSetup.runningTime,
                speedUnit: this.form.timingSetup.speedUnit,
                useTolerances: this.form.timingSetup.useTolerances,
                speedEvent: this.form.timingSetup.speedEvent,
                measuringPoints: [],
                regularitySettings: {
                  enabled: this.form.timingSetup.regularity !== "DISABLED",
                  type:
                    this.form.timingSetup.regularity === "DISABLED"
                      ? "TARGET_TIME"
                      : this.form.timingSetup.regularity
                }
              }
            };

            this.form.timingSetup.measuringPoints.forEach((mp, mpIndex) => {
              if (mpIndex === 0) {
                payload.data.measuringPoints.push({
                  devices: []
                });
              } else {
                let minSpeed = mp.speedMeasurementMinSpeed;
                let maxSpeed = mp.speedMeasurementMaxSpeed;

                switch (this.form.timingSetup.speedUnit) {
                  case "KM_H":
                    minSpeed /= 3.6;
                    maxSpeed /= 3.6;
                    break;
                  case "MP_H":
                    minSpeed /= 2.23694;
                    maxSpeed /= 2.23694;
                    break;
                  default:
                    break;
                }

                payload.data.measuringPoints.push({
                  devices: [],
                  sectionRankingSettings: mp.sectionRankingSettings,
                  tolerance: {
                    lowerBound: mp.toleranceLowerBound,
                    isLowerPercentage: mp.toleranceIsLowerPercentage,
                    upperBound: mp.toleranceUpperBound,
                    isUpperPercentage: mp.toleranceIsUpperPercentage
                  },
                  speedMeasurement: {
                    distance: mp.speedMeasurementEnabled
                      ? mp.speedMeasurementDistance * 1000
                      : 0,
                    minSpeed: mp.speedMeasurementEnabled ? minSpeed : 0,
                    maxSpeed: mp.speedMeasurementEnabled ? maxSpeed : 0
                  },
                  sectionRankingSettings: {
                    sectionTimeRanking: this.form.timingSetup
                      .sectionTimeRanking,
                    splitTimeRanking: this.form.timingSetup.splitTimeRanking,
                    speedRanking: this.form.timingSetup.speedRanking
                  }
                });
              }

              mp.devices.forEach((item, itemIndex) => {
                payload.data.measuringPoints[mpIndex].devices.push({
                  id: item.deviceId,
                  channel: deviceTypes["MT1"].channels[item.channelIndex].name
                });
              });
            });

            this.updateItem(payload)
              .then(response => {
                this.steps[4].success = "forms.event.updateSuccess";
                this.$refs["form-timingSetup"][0].$v.$reset();
                resolve();
              })
              .catch(response => {
                switch (response.data.status) {
                  case -3014:
                    this.steps[4].error = this.$i18n.t(
                      "forms.event.errors.updateFailed"
                    );
                    break;
                  default:
                    this.steps[4].error = this.$i18n.t(
                      "errors.unexpectedError"
                    );
                }

                reject();
              });
          })
          .catch(stepData => {
            this.steps[4].error = this.$i18n.t(
              "forms.event.errors.invalidForm"
            );

            reject();
          });
      });
    },
    updateMassStartSettings() {
      return new Promise((resolve, reject) => {
        this.steps[4].success = null;
        this.steps[4].error = null;

        this.$refs["form-timingSetup"][0]
          .submit()
          .then(stepData => {
            Object.assign(this.form.timingSetup, stepData.form);

            const payload = {
              eventId: this.event.id,
              data: {
                massStartFieldId: stepData.form.massStartFieldId
              }
            };

            this.updateEventSettingsMassStart(payload)
              .then(response => {
                this.steps[4].success = "forms.event.updateSuccess";
                this.$refs["form-timingSetup"][0].$v.$reset();
                resolve();
              })
              .catch(response => {
                switch (response.data.status) {
                  default:
                    this.steps[4].error = this.$i18n.t(
                      "errors.unexpectedError"
                    );
                }

                reject();
              });
          })
          .catch(stepData => {
            this.steps[4].error = this.$i18n.t(
              "forms.event.errors.invalidForm"
            );

            reject();
          });
      });
    },
    calculateDates() {
      return new Promise((resolve, reject) => {
        let dates = [];

        if (!this.form.event.date.from.timestamp) {
          return;
        }

        let startDate = new Date(
          new Date(this.form.event.date.from.timestamp).getFullYear(),
          new Date(this.form.event.date.from.timestamp).getMonth(),
          new Date(this.form.event.date.from.timestamp).getDate()
        );

        if (
          this.form.event.date.multiDay &&
          this.form.event.date.to.timestamp &&
          this.form.event.date.from.timestamp <
            this.form.event.date.to.timestamp
        ) {
          dates.push({
            date: startDate.getTime(),
            items: []
          });

          while (
            startDate.getTime() !==
            new Date(
              new Date(this.form.event.date.to.timestamp).getFullYear(),
              new Date(this.form.event.date.to.timestamp).getMonth(),
              new Date(this.form.event.date.to.timestamp).getDate()
            ).getTime()
          ) {
            startDate.setDate(startDate.getDate() + 1);
            dates.push({
              date: startDate.getTime(),
              items: []
            });
          }
        } else {
          dates.push({
            date: startDate.getTime(),
            items: []
          });
        }

        dates = dates.map((date, dateIndex) => {
          if (this.form.heats.dates[dateIndex]) {
            const heatItem = this.form.heats.dates[dateIndex];
            heatItem.date = date.date;
            heatItem.items.map(item => {
              const curTime = new Date(item.startTime);
              const newDate = new Date(heatItem.date);

              item.startTime = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate(),
                curTime.getHours(),
                curTime.getMinutes()
              ).getTime();
            });

            return heatItem;
          }

          return date;
        });

        this.form.heats.dates = dates;
        resolve();
      });
    },
    initData() {
      return new Promise((resolve, reject) => {
        // Event
        this.form.event.name = this.event.name;
        this.form.event.place = this.event.place;
        this.form.event.country = this.event.country;
        this.form.event.date.multiDay =
          this.event.start === this.event.end ? false : true;
        this.form.event.date.from.timestamp = new Date(
          this.event.start * 1000
        ).getTime();
        this.form.event.date.to.timestamp = new Date(
          this.event.end * 1000
        ).getTime();
        this.form.event.publicResult = this.event.publicResult;
        this.form.event.registration.public = this.event.registration.public;
        this.form.event.registration.from.timestamp = this.event.registration
          .from
          ? new Date(this.event.registration.from).getTime()
          : null;
        this.form.event.registration.to.timestamp = this.event.registration.to
          ? new Date(this.event.registration.to).getTime()
          : null;

        // Organizer
        this.form.organizer.name = this.event.organizer.organizerName;
        this.form.organizer.website = this.event.organizer.organizerWebsite;

        // Heats
        this.calculateDates().then(() => {
          for (let i = 0; i < this.event.heats.length; i++) {
            const heat = this.event.heats[i];
            const date = new Date(
              new Date(heat.startTime).getFullYear(),
              new Date(heat.startTime).getMonth(),
              new Date(heat.startTime).getDate()
            ).getTime();

            for (let j = 0; j < this.form.heats.dates.length; j++) {
              if (this.form.heats.dates[j].date === date) {
                this.form.heats.dates[j].items.push({
                  id: heat.id,
                  name: heat.name,
                  startTime: heat.startTime,
                  showTimePicker: false,
                  isRated: !heat.excludeFromOverallRanking
                });
              }
            }
          }

          // Ranking Settings
          this.form.heats.rankingBestOf.enabled =
            this.event.settings.rankingBestOfCount > 0;
          this.form.heats.rankingBestOf.count = this.event.settings.rankingBestOfCount;

          // Registration
          this.form.registration.data = this.event.competitorData.data;

          // Timing Setup
          const precisionIndex = this.precisions.findIndex(
            el => el === this.event.setup.precision
          );
          const speedPrecisionIndex = this.speedPrecisions.findIndex(
            el => el === this.event.setup.speedPrecision
          );
          this.form.timingSetup.precision = this.precisions[precisionIndex];
          this.form.timingSetup.rounding = this.event.setup.rounding;
          this.form.timingSetup.speedPrecision = this.speedPrecisions[
            speedPrecisionIndex
          ];
          this.form.timingSetup.speedRounding = this.event.setup.speedRounding;
          this.form.timingSetup.runningTime = this.event.setup.showRunningTime;
          this.form.timingSetup.speedUnit = this.event.setup.speedUnit;
          this.form.timingSetup.useTolerances = this.event.setup.useTolerances;
          this.form.timingSetup.speedEvent = this.event.setup.speedEvent;
          this.form.timingSetup.sectionTimeRanking = true;
          this.form.timingSetup.splitTimeRanking = true;
          this.form.timingSetup.speedRanking = true;

          if (this.event.setup.regularitySettings.enabled) {
            this.form.timingSetup.regularity = this.event.setup.regularitySettings.type;
          }

          for (let i = 0; i < this.event.setup.sections.length; i++) {
            let minSpeed =
              this.event.setup.sections[i].minSpeed > 0
                ? this.event.setup.sections[i].minSpeed
                : 0;
            let maxSpeed =
              this.event.setup.sections[i].maxSpeed > 0
                ? this.event.setup.sections[i].maxSpeed
                : 0;

            if (
              minSpeed >= 0 &&
              maxSpeed >= 0 &&
              this.event.setup.speedUnit !== null
            ) {
              switch (this.event.setup.speedUnit) {
                case "KM_H":
                  minSpeed = (minSpeed * 3.6).toFixed(1);
                  maxSpeed = (maxSpeed * 3.6).toFixed(1);
                  break;
                case "MP_H":
                  minSpeed = (minSpeed * 2.23694).toFixed(1);
                  maxSpeed = (maxSpeed * 2.23694).toFixed(1);
                  break;
                default:
                  break;
              }
            }

            this.form.timingSetup.measuringPoints.push({
              id: cuid(),
              devices: [],
              sectionRankingSettings: this.event.setup.sections[i]
                .sectionRankingSettings,
              toleranceLowerBound: this.event.setup.sections[i].tolerance
                ? this.event.setup.sections[i].tolerance.lowerBound
                : null,
              toleranceIsLowerPercentage: this.event.setup.sections[i].tolerance
                ? this.event.setup.sections[i].tolerance.isLowerPercentage
                : null,
              toleranceUpperBound: this.event.setup.sections[i].tolerance
                ? this.event.setup.sections[i].tolerance.upperBound
                : null,
              toleranceIsUpperPercentage: this.event.setup.sections[i].tolerance
                ? this.event.setup.sections[i].tolerance.isUpperPercentage
                : null,
              speedMeasurementEnabled:
                this.event.setup.sections[i].distance > 0,
              speedMeasurementDistance:
                this.event.setup.sections[i].distance > 0
                  ? (this.event.setup.sections[i].distance / 1000).toFixed(2)
                  : 0,
              speedMeasurementMinSpeed: minSpeed,
              speedMeasurementMaxSpeed: maxSpeed
            });

            for (
              let j = 0;
              j < this.event.setup.sections[i].devices.length;
              j++
            ) {
              const channelIndex = deviceTypes["MT1"].channels.findIndex(
                channel =>
                  channel.name ===
                  this.event.setup.sections[i].devices[j].channel
              );

              this.form.timingSetup.measuringPoints[i].devices.push({
                deviceId: this.event.setup.sections[i].devices[j].deviceId,
                channelIndex
              });
            }
          }

          // Mass Start Settings
          this.form.timingSetup.massStartFieldId = this.event.settings.massStartFieldId;

          resolve();
        });
      });
    },
    backButtonAction() {
      this.$router.push({ name: "Events" });
    },
    submitButtonAction() {
      this.submit().catch(error => console.log(error));
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.progress = 0;
        this.actionLoading = true;

        const invalidSteps = this.steps.reduce((re, el) => {
          this.$refs[`form-${el.id}`][0].$v.$touch();
          if (this.$refs[`form-${el.id}`][0].$v.$invalid) {
            re.push(el.id);
          }
          return re;
        }, []);

        if (invalidSteps.length > 0) {
          let invalidStepsString = "";
          for (let i = 0; i < invalidSteps.length; i++) {
            invalidStepsString += this.$i18n.t(
              `forms.event.${invalidSteps[i]}`
            );

            if (i < invalidSteps.length - 1) {
              invalidStepsString += ", ";
            }
          }

          for (let i = 0; i < this.steps.length; i++) {
            this.steps[i].error = null;
            this.steps[i].success = null;
            if (invalidSteps.includes(this.steps[i].id)) {
              this.steps[i].error = this.$i18n.t(
                "forms.event.errors.invalidSteps",
                {
                  steps: invalidStepsString
                }
              );
            }
          }
          this.steps[Number(this.currentStepNumber)].error = this.$i18n.t(
            "forms.event.errors.invalidSteps",
            {
              steps: invalidStepsString
            }
          );
          this.actionLoading = false;
          reject();
        } else {
          this.updateEvent()
            .then(() => {
              this.progress += 14.28;
              return this.updateOrganizer();
            })
            .then(() => {
              this.progress += 14.28;
              return this.updateHeats();
            })
            .then(() => {
              this.progress += 14.28;
              return this.updateRankingSettings();
            })
            .then(() => {
              this.progress += 14.28;
              return this.updateRegistration();
            })
            .then(() => {
              this.progress += 14.28;
              return this.updateTimingSetup();
            })
            .then(() => {
              this.progress += 14.28;
              return this.updateMassStartSettings();
            })
            .then(() => {
              this.progress = 100;
              this.actionLoading = false;
              resolve();
            })
            .catch(() => {
              this.actionLoading = false;
              this.progress = 0;
              this.steps[Number(this.currentStepNumber)].error = this.$i18n.t(
                "forms.event.errors.formNotUpdated"
              );
              reject();
            });
        }
      });
    }
  },
  created() {
    this.fetchSelectedEvent(this.$props.id)
      .then(() => {
        if (!this.userIsOwner && !this.userIsAdmin) {
          this.$router.push({ name: "Home" });
        }
      })
      .then(() => this.fetchDevices(this.user.id).catch(() => {}))
      .then(() => {
        this.initData();
        this.formLoading = false;
      })
      .catch(() => {});
  },
  beforeRouteLeave(to, from, next) {
    this.dirtyForms = null;

    const dirtyForms = this.steps.reduce((re, el) => {
      if (this.$refs[`form-${el.id}`][0].$v.$anyDirty) {
        el.error = this.$i18n.t("forms.event.errors.notSaved");
        re.push(el);
      }

      return re;
    }, []);

    if (dirtyForms.length > 0) {
      this.dirtyForms = dirtyForms;

      this.$refs.dialog.confirm().then(response => {
        if (response) {
          this.submit()
            .then(() => next())
            .catch(error => console.log(error));
        } else {
          next();
        }
      });
    } else {
      next();
    }
  }
};
</script>
