<template>
  <v-form class="mx-4">
    <v-container>
      <v-row v-if="numberORatedfHeats > 1">
        <v-col>
          <v-card class="pb-4">
            <v-card-text class="d-flex align-end">
              <v-checkbox
                :label="$t('forms.event.bestOfRuns')"
                v-model="form.rankingBestOf.enabled"
                hide-details
                class="mr-4"
              />
              <v-slider
                v-model="form.rankingBestOf.count"
                min="1"
                :max="numberORatedfHeats"
                ticks="always"
                tick-size="4"
                :tick-labels="heatTicks"
                :disabled="!form.rankingBestOf.enabled"
                hide-details
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-card-subtitle v-if="!$v.form.dates.required" class="error--text">
          {{ $t("forms.event.chooseDate") }}
        </v-card-subtitle>
        <v-col sm="12">
          <v-card
            v-for="(date, dateIndex) in form.dates"
            :key="`date-${dateIndex}`"
            class="mb-2"
            tile
          >
            <v-card-title>
              {{ $d(date.date, "shortDate") }}
            </v-card-title>
            <v-card-subtitle v-if="dateError(dateIndex)" class="error--text">
              {{ dateError(dateIndex) }}
            </v-card-subtitle>
            <v-card-text class="py-0">
              <v-row
                v-for="(heat, heatIndex) in form.dates[dateIndex].items"
                :key="`heat-${heatIndex}`"
              >
                <v-col cols="4">
                  <v-text-field
                    :label="
                      $t('forms.event.heatName', { number: heatIndex + 1 })
                    "
                    v-model="
                      $v.form.dates.$each.$iter[dateIndex].items.$each.$iter[
                        heatIndex
                      ].name.$model
                    "
                    :error-messages="nameErrors(dateIndex, heatIndex)"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-menu
                    v-model="
                      form.dates[dateIndex].items[heatIndex].showTimePicker
                    "
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :label="
                          $t('forms.event.heatStartTime', {
                            number: heatIndex + 1
                          })
                        "
                        :value="
                          $d(
                            form.dates[dateIndex].items[heatIndex].startTime,
                            'time'
                          )
                        "
                        prepend-icon="mdi-clock-outline"
                        readonly
                        v-on="on"
                        @click:prepend="
                          form.dates[dateIndex].items[
                            heatIndex
                          ].showTimePicker = true
                        "
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="
                        form.dates[dateIndex].items[heatIndex].showTimePicker
                      "
                      :value="
                        form.dates[dateIndex].items[heatIndex].startTime
                          | moment('HH:mm')
                      "
                      format="24hr"
                      full-width
                      @input="
                        value =>
                          startTimePickerHandler(value, dateIndex, heatIndex)
                      "
                      @click:minute="
                        form.dates[dateIndex].items[
                          heatIndex
                        ].showTimePicker = false
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="2">
                  <v-checkbox
                    v-model="
                      $v.form.dates.$each.$iter[dateIndex].items.$each.$iter[
                        heatIndex
                      ].isRated.$model
                    "
                    hide-details
                  >
                    <template v-slot:label>
                      {{ $t("forms.event.isRated") }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small right>
                            mdi-information-outline
                          </v-icon>
                        </template>
                        {{ $t("forms.event.hints.rated") }}
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="2" class="d-flex justify-center align-center">
                  <v-btn @click="removeHeatFromDate(dateIndex, heatIndex)" icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                @click="addHeatToDate(dateIndex)"
                class="ma-2"
                color="primary"
                icon
              >
                <v-icon large>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
/* eslint-disable */

import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "FormHeats",
  props: {
    wizardData: {
      type: Object,
      required: true
    },
    currentStepNumber: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      stepNumber: 3,
      form: {
        rankingBestOf: {
          enabled: false,
          count: 0
        },
        dates: []
      }
    };
  },
  validations() {
    return {
      form: {
        dates: {
          required,
          $each: {
            items: {
              $each: {
                name: {
                  required,
                  maxLength: maxLength(120)
                },
                isRated: { required },
                startTime: { required }
              }
            }
          }
        }
      }
    };
  },
  computed: {
    numberORatedfHeats() {
      return this.form.dates.reduce(
        (re, date) => (re += date.items.filter(heat => heat.isRated).length),
        0
      );
    },
    heatTicks() {
      return Array.from(Array(this.numberORatedfHeats).keys(), n => n + 1);
    },
    rankingBestOfEnabled() {
      return this.form.rankingBestOf.enabled;
    }
  },
  watch: {
    currentStepNumber(newCurrentStepNumber) {
      if (newCurrentStepNumber && newCurrentStepNumber === this.stepNumber) {
        this.assignData();
      }
    },
    numberORatedfHeats(newnumberORatedfHeats) {
      if (newnumberORatedfHeats === 1) {
        this.form.rankingBestOf.enabled = false;
        this.form.rankingBestOf.count = 0;
      }
    },
    rankingBestOfEnabled(newrankingBestOfEnabled) {
      if (newrankingBestOfEnabled) {
        if (this.form.rankingBestOf.count === 0) {
          this.form.rankingBestOf.count = 1;
        }
      } else {
        this.form.rankingBestOf.count = 0;
      }
    }
  },
  methods: {
    dateError(dateIndex) {
      const errors = [];
      this.$v.form.dates.$each.$iter[dateIndex].required &&
        errors.push(this.$i18n.t("forms.event.errors.heatDate.required"));

      if (errors.length > 0) {
        return errors[0];
      }

      return null;
    },
    nameErrors(dateIndex, heatIndex) {
      const errors = [];
      if (
        !this.$v.form.dates.$each.$iter[dateIndex].items.$each.$iter[heatIndex]
          .name.$dirty
      )
        return errors;
      !this.$v.form.dates.$each.$iter[dateIndex].items.$each.$iter[heatIndex]
        .name.required &&
        errors.push(this.$i18n.t("forms.event.errors.heatName.required"));
      !this.$v.form.dates.$each.$iter[dateIndex].items.$each.$iter[heatIndex]
        .name.maxLength &&
        errors.push(
          this.$i18n.t("forms.event.errors.heatName.maxLength", { length: 120 })
        );

      return errors;
    },
    startTimePickerHandler(value, dateIndex, itemIndex) {
      this.$v.form.$touch();

      this.form.dates[dateIndex].items[
        itemIndex
      ].startTime = this.parseTimeToTimestamp(
        value,
        this.form.dates[dateIndex].items[itemIndex].startTime
      );
    },
    parseTimeToTimestamp(time, curTimestamp) {
      const curDate = new Date(curTimestamp);
      const year = curDate.getFullYear();
      const monthIndex = curDate.getMonth();
      const day = curDate.getDate();

      const newTime = time.split(":");
      const hours = newTime[0];
      const minutes = newTime[1];

      return new Date(year, monthIndex, day, hours, minutes).getTime();
    },
    assignData() {
      Object.assign(this.form, this.wizardData.heats);
    },
    submit() {
      this.$v.$touch();
      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve({
            step: "heats",
            form: this.form
          });
        } else {
          reject({
            step: "heats",
            form: this.form
          });
        }
      });
    },
    addHeatToDate(dateIndex) {
      const newNumber = this.form.dates[dateIndex].items.length;

      const startTime = new Date(this.form.dates[dateIndex].date);
      startTime.setHours(12, 0);

      const heat = {
        id: null,
        name: this.$i18n.t("forms.event.heatNamePreFill", {
          number: newNumber + 1
        }),
        startTime: startTime.getTime(),
        showTimePicker: false,
        isRated: true
      };

      this.form.dates[dateIndex].items.push(heat);

      this.$v.form.$touch();
    },
    removeHeatFromDate(dateIndex, heatIndex) {
      this.form.dates[dateIndex].items.splice(heatIndex, 1);
      this.$v.form.$touch();
    }
  },
  created() {
    this.assignData();
  },
  activated() {
    this.assignData();
  }
};
</script>
