<template>
  <v-card>
    <div v-if="devices">
      <div class="d-flex justify-space-between">
        <v-card-title>
          {{ $t("forms.device.title", { device: device.id }) }}
        </v-card-title>
        <v-btn v-if="close" class="ma-4" icon @click="$emit('closed')">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>

      <v-tabs v-model="activeTab" grow show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.id">
          {{ tab.name }}
        </v-tab>
        <v-tab-item key="general">
          <v-card flat>
            <v-card-title>
              {{ $t("forms.device.settings") }}
            </v-card-title>
            <v-card-text>
              <PanelDevicesEditName ref="editName" :device="device" />
              <PanelDevicesEditTimezone ref="editTimezone" :device="device" />
              <PanelDevicesEditSync ref="editSync" :device="device" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-title>
              {{ $t("forms.device.deviceData") }}
            </v-card-title>
            <v-simple-table class="px-4 pb-4">
              <tr>
                <td class="font-weight-bold">
                  {{ $t("forms.device.SIMStatus") }}
                </td>
                <td v-if="SIMStatus.isLoading">
                  <v-progress-circular
                    indeterminate
                    size="12"
                    width="2"
                  ></v-progress-circular>
                </td>
                <td v-else>
                  {{ SIMStatus.status }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">
                  {{ $t("forms.device.syncState") }}
                </td>
                <td>
                  {{
                    getSyncState(device.deviceComponents.TimingInfo.syncState)
                  }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">
                  {{ $t("forms.device.battery") }}
                </td>
                <td>
                  {{
                    device.deviceComponents.BatteryInfo.isCharging
                      ? $t("forms.device.isCharging")
                      : `${device.deviceComponents.BatteryInfo.batteryPercentage}%`
                  }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">
                  {{ $t("forms.device.externalVoltage") }}
                </td>
                <td>
                  <span
                    v-if="
                      device.deviceComponents.BatteryInfo
                        .isExternalVoltageSupplied
                    "
                  >
                    {{ $t("forms.device.yes") }}
                  </span>
                  <span v-else>
                    {{ $t("forms.device.no") }}
                  </span>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">
                  {{ $t("forms.device.curTriggerCosts") }}
                </td>
                <td>
                  {{
                    $t("forms.device.costsPerTrigger", {
                      number: device.deviceComponents.BillingSettings
                        .triggerCosts
                        ? device.deviceComponents.BillingSettings.triggerCosts
                        : 2
                    })
                  }}
                </td>
              </tr>
            </v-simple-table>
          </v-card>
        </v-tab-item>
        <v-tab-item key="channels">
          <v-card flat>
            <PanelDevicesEditChannelStatus
              ref="editChannelStatus"
              :device="device"
              minFirmwareVersion="21.82"
            />

            <PanelDevicesEditChannel
              v-for="(channel, index) in channels"
              :key="`channel-${channel.name}`"
              :ref="`editChannel${channel.name}`"
              :device="device"
              :channel="channel"
              :index="index"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item key="trigger">
          <PanelDevicesEditTrigger :device="device" :channels="channels" />
        </v-tab-item>
      </v-tabs>
      <v-alert v-if="deviceActivation.showInfo" text tile color="warning">
        <template v-slot:prepend>
          <v-progress-circular
            v-if="deviceActivation.isLoading"
            indeterminate
            size="20"
            width="3"
            class="mr-3"
          ></v-progress-circular>
          <v-icon v-else color="warning">
            mdi-exclamation
          </v-icon>
        </template>

        <span>
          {{ $t(deviceActivation.message) }}
        </span>
        <button
          v-if="deviceActivation.showAction"
          class="text__clickable"
          @click="activateDevice"
        >
          {{ $t("forms.device.reactivateDeviceCommunication") }}
        </button>
      </v-alert>
    </div>
    <v-card-text v-else></v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";
import PanelDevicesEditName from "@/components/PanelDevicesEditName";
import PanelDevicesEditTimezone from "@/components/PanelDevicesEditTimezone";
import PanelDevicesEditSync from "@/components/PanelDevicesEditSync";
import PanelDevicesEditChannel from "@/components/PanelDevicesEditChannel";
import PanelDevicesEditChannelStatus from "@/components/PanelDevicesEditChannelStatus";
import PanelDevicesEditTrigger from "@/components/PanelDevicesEditTrigger";
import DeviceTypes from "@/utils/deviceTypes";

export default {
  name: "PanelDevicesEdit",
  props: {
    id: {
      type: String,
      required: true
    },
    close: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PanelDevicesEditName,
    PanelDevicesEditTimezone,
    PanelDevicesEditSync,
    PanelDevicesEditChannel,
    PanelDevicesEditChannelStatus,
    PanelDevicesEditTrigger
  },
  data() {
    return {
      activeTab: null,
      deviceActivation: {
        showInfo: false,
        showAction: false,
        isLoading: false,
        message: ""
      },
      SIMStatus: {
        isLoading: true,
        status: null
      }
    };
  },
  computed: {
    ...mapState({
      devices: state => state.devices.items
    }),
    device() {
      return this.$store.getters["devices/getItemById"](this.$props.id);
    },
    tabs() {
      return [
        {
          id: "general",
          name: this.$i18n.t("forms.device.general")
        },
        {
          id: "channels",
          name: this.$i18n.t("forms.device.channels")
        },
        {
          id: "trigger",
          name: this.$i18n.t("forms.device.trigger")
        }
      ];
    },
    channels() {
      return DeviceTypes[this.device.type].channels;
    }
  },
  methods: {
    ...mapActions({
      activateItem: "devices/activateItem",
      fetchDeviceSIMStatus: "devices/fetchDeviceSIMStatus"
    }),
    activateDevice() {
      this.deviceActivation.isLoading = true;
      this.deviceActivation.showAction = false;

      const payload = {
        deviceId: this.$props.id
      };

      this.activateItem(payload)
        .then(() => {
          this.deviceActivation.isLoading = false;
          this.deviceActivation.showInfo = false;
        })
        .catch(response => {
          this.deviceActivation.isLoading = false;
          this.deviceActivation.showInfo = true;

          switch (response.data.status) {
            case -2015:
              this.deviceActivation.message =
                "forms.device.errors.SIMActivation";
              break;
            default:
              this.deviceActivation.showAction = true;
              this.deviceActivation.message =
                "forms.device.errors.communicationIssue";
              break;
          }
        });
    },
    getSyncState(state) {
      switch (state) {
        case "NOT_SYNCED":
          return this.$i18n.t("forms.device.syncStates.notSynced");
        case "GPS_SYNCED":
          return this.$i18n.t("forms.device.syncStates.gpsSynced");
        case "EXTERNAL_SYNCED":
          return this.$i18n.t("forms.device.syncStates.externalSynced");
        case "SERVER_SYNCED":
          return this.$i18n.t("forms.device.syncStates.serverSynced");
        case "AUTO_SYNC":
          return this.$i18n.t("forms.device.syncStates.autoSync");
        default:
          return type;
      }
    },
    handleDeviceStatusSIMUpdate() {
      this.SIMStatus.isLoading = true;

      const payload = {
        deviceId: this.$props.id
      };

      this.fetchDeviceSIMStatus(payload)
        .then(response => {
          this.SIMStatus.status = response.data.data[0]
            ? this.$i18n.t("forms.device.activated")
            : this.$i18n.t("forms.device.deactivated");
          this.SIMStatus.isLoading = false;
        })
        .catch(response => {
          console.log("catch", response);
          this.SIMStatus.status = this.$i18n.t("forms.device.errors.SIMStatus");
          this.SIMStatus.isLoading = false;
        });
    },
    initForms() {
      this.$nextTick(() => {
        this.$refs.editName && this.$refs.editName.initData();
        this.$refs.editTimezone && this.$refs.editTimezone.initData();
        this.$refs.editSync && this.$refs.editSync.initData();
        this.$refs.editChannelStatus && this.$refs.editChannelStatus.initData();
        this.$refs.editChannelC0 &&
          this.$refs.editChannelC0[0] &&
          this.$refs.editChannelC0[0].initData();
        this.$refs.editChannelC1 &&
          this.$refs.editChannelC1[0] &&
          this.$refs.editChannelC1[0].initData();
      });
    }
  },
  mounted() {
    this.activateDevice();
    this.handleDeviceStatusSIMUpdate();

    this.$store.subscribe(mutation => {
      const whitelist = ["devices/updateItemInItems"];

      if (whitelist.includes(mutation.type)) {
        const device = mutation.payload;

        if (device.id === this.device.id) {
          this.initForms();
        }
      }
    });
  }
};
</script>
<style scoped>
.text__clickable {
  cursor: pointer;
  text-decoration: underline;
  outline: none;
}
</style>
