import moment from "moment";

const mixin = {
  methods: {
    formatTimestamp_100ns(
      timestamp_100ns,
      deviceOffset = null,
      includeDate = true,
      decimalPlaces = 5
    ) {
      if (!timestamp_100ns && !deviceOffset) {
        return "-";
      }

      let offset = 0;

      if (deviceOffset !== null) {
        offset = (deviceOffset * -1) / 60;
      } else {
        offset = new Date().getTimezoneOffset() / 60;
      }

      let ts = timestamp_100ns - 36000000000 * offset;

      const dateTime = moment(ts / 10000).utcOffset(0);

      let remainder = ts;

      let hours = Math.floor(remainder / 36000000000) % 24;
      remainder = Math.floor(remainder % 36000000000);

      const minutes = String(Math.floor(remainder / 600000000)).padStart(
        2,
        "0"
      );
      remainder = Math.floor(remainder % 600000000);

      const seconds = String(Math.floor(remainder / 10000000)).padStart(2, "0");
      remainder = Math.floor(remainder % 10000000);

      decimalPlaces = Math.max(0, Math.min(decimalPlaces, 5));

      let remStr = String(remainder)
        .padStart(7, "0")
        .substring(0, decimalPlaces);

      let output = `${hours}:${minutes}:${seconds}`;
      output = decimalPlaces > 0 ? `${output}.${remStr}` : `${output}`;

      if (includeDate) {
        output = output + " " + this.$i18n.d(dateTime, "shortDate");
      }
      return output;
    },
    timestampToDate(timestamp_100ns, offset_m = 0) {
      const offset_h = (offset_m * -1) / 60;
      const ts = timestamp_100ns - 36000000000 * offset_h;
      const date = new Date(ts / 10000);
      let remainder = ts;

      const hours = Math.floor(remainder / 36000000000) % 24;
      remainder = Math.floor(remainder % 36000000000);

      const minutes = String(Math.floor(remainder / 600000000));
      remainder = Math.floor(remainder % 600000000);

      const seconds = String(Math.floor(remainder / 10000000));
      remainder = Math.floor(remainder % 10000000);

      const milliseconds = String(Math.floor(remainder / 10000));

      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        hours,
        minutes,
        seconds,
        milliseconds
      );
    }
  }
};

export default mixin;
