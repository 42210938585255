<template>
  <v-form class="mx-4">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            :label="$t('forms.event.name')"
            name="name"
            v-model="$v.form.name.$model"
            :error-messages="nameErrors"
            autofocus
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            :label="$t('forms.event.place')"
            name="place"
            v-model="$v.form.place.$model"
            :error-messages="placeErrors"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            :label="$t('forms.event.country')"
            name="country"
            v-model="$v.form.country.$model"
            :error-messages="countryErrors"
            :items="getItemsByLang"
            hide-no-data
            auto-select-first
            autocomplete="new-password"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12">
          <v-label>{{ $t("forms.event.multiDay") }}</v-label>
          <v-switch
            name="dateMultiDay"
            v-model="$v.form.date.multiDay.$model"
            @change="$emit('dateChanged')"
          ></v-switch>
        </v-col>

        <v-col cols="12" sm="6">
          <v-menu
            v-model="form.date.from.showDatePicker"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                ref="eventDateFrom"
                :label="$t('forms.event.date')"
                name="dateFrom"
                :value="$d(form.date.from.timestamp, 'shortDate')"
                :error-messages="dateFromErrors"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                @click:prepend="form.date.from.showDatePicker = true"
              >
                <template v-slot:append>
                  <v-tooltip
                    v-if="editing && form.date.from.timestamp < today"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="warning"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    {{ $t("forms.event.pastDate") }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              :value="form.date.from.timestamp | moment('YYYY-MM-DD')"
              no-title
              show-current
              first-day-of-week="1"
              @input="eventFromDatePickerHandler"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col v-if="form.date.multiDay" cols="12" sm="6">
          <v-menu
            v-model="form.date.to.showDatePicker"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :label="$t('forms.event.date')"
                name="dateTo"
                :value="$d(form.date.to.timestamp, 'shortDate')"
                :error-messages="dateToErrors"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                @click:prepend="form.date.to.showDatePicker = true"
              >
                <template v-slot:append>
                  <v-tooltip
                    v-if="editing && form.date.to.timestamp < today"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="warning"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    {{ $t("forms.event.pastDate") }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              :value="form.date.to.timestamp | moment('YYYY-MM-DD')"
              no-title
              show-current
              first-day-of-week="1"
              @input="eventToDatePickerHandler"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12">
          <v-file-input
            :label="$t('forms.event.image')"
            name="image"
            v-model="form.image.file"
            :loading="form.image.loading"
            prepend-icon="mdi-image"
            accept="image/*"
            @change="fileInputHandler"
            show-size
            :hint="
              $t('forms.event.hints.eventImage', { height: 300, width: 600 })
            "
            persistent-hint
          ></v-file-input>
        </v-col>

        <v-col cols="12">
          <div class="ml-md-8" style="overflow: auto">
            <canvas
              id="eventImagePreview"
              class="elevation-2"
              height="0"
              width="0"
            ></canvas>
          </div>
        </v-col>

        <v-col cols="12">
          <v-label>{{ $t("forms.event.publicResult") }}</v-label>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-1" small
                >mdi-alert-circle-outline</v-icon
              >
            </template>
            {{ $t("forms.event.hints.publicResult") }}
          </v-tooltip>
          <v-switch name="publicResult" v-model="$v.form.publicResult.$model" />
        </v-col>

        <v-col cols="12">
          <v-label>{{ $t("forms.event.publicRegistration") }}</v-label>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-1" small
                >mdi-alert-circle-outline</v-icon
              >
            </template>
            {{ $t("forms.event.hints.publicRegistration") }}
          </v-tooltip>
          <v-switch
            name="publicRegistration"
            v-model="$v.form.registration.public.$model"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-menu
            v-if="form.registration.public"
            v-model="form.registration.from.showDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :label="$t('forms.event.startDate')"
                :value="$d(form.registration.from.timestamp, 'shortDate')"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                @click:prepend="form.registration.from.showDatePicker = true"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="form.registration.from.timestamp | moment('YYYY-MM-DD')"
              no-title
              show-current
              first-day-of-week="1"
              @input="registrationFromDatePickerHandler"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6">
          <v-menu
            v-if="form.registration.public"
            ref="publicRegistrationFromTimePicker"
            v-model="form.registration.from.showTimePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :label="$t('forms.event.startTime')"
                :value="$d(form.registration.from.timestamp, 'time')"
                prepend-icon="mdi-clock-outline"
                readonly
                v-on="on"
                @click:prepend="form.registration.from.showTimePicker = true"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="form.registration.from.showTimePicker"
              :value="form.registration.from.timestamp | moment('HH:mm')"
              format="24hr"
              full-width
              @input="registrationFromTimePickerHandler"
              @click:minute="form.registration.from.showTimePicker = false"
            ></v-time-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6">
          <v-menu
            v-if="form.registration.public"
            v-model="form.registration.to.showDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :label="$t('forms.event.endDate')"
                :value="$d(form.registration.to.timestamp, 'shortDate')"
                :error-messages="publicRegistrationToErrors"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                @click:prepend="form.registration.to.showDatePicker = true"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="form.registration.to.timestamp | moment('YYYY-MM-DD')"
              no-title
              show-current
              first-day-of-week="1"
              @input="registrationToDatePickerHandler"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6">
          <v-menu
            v-if="form.registration.public"
            ref="publicRegistrationToTimePicker"
            v-model="form.registration.to.showTimePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :label="$t('forms.event.endTime')"
                :value="$d(form.registration.to.timestamp, 'time')"
                :error-messages="publicRegistrationToErrors"
                prepend-icon="mdi-clock-outline"
                readonly
                v-on="on"
                @click:prepend="form.registration.to.showTimePicker = true"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="form.registration.to.showTimePicker"
              :value="form.registration.to.timestamp | moment('HH:mm')"
              format="24hr"
              full-width
              @input="registrationToTimePickerHandler"
              @click:minute="form.registration.to.showTimePicker = false"
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapGetters } from "vuex";
import { maxLength, required, requiredIf } from "vuelidate/lib/validators";
import minDate from "@/utils/validators/minDate";
import maxDuration from "@/utils/validators/maxDuration";

export default {
  name: "FormGeneral",
  props: {
    event: {
      type: Object,
      default: null
    },
    wizardData: {
      type: Object,
      required: true
    },
    currentStepNumber: {
      type: Number,
      default: null
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stepNumber: 1,
      form: {
        name: null,
        place: null,
        country: null,
        date: {
          multiDay: null,
          from: {
            showDatePicker: false,
            timestamp: null
          },
          to: {
            showDatePicker: false,
            timestamp: null
          }
        },
        image: {
          file: null,
          data: null,
          loading: false
        },
        showDatePicker: false,
        publicResult: true,
        registration: {
          public: false,
          from: {
            showDatePicker: false,
            showTimePicker: false,
            timestamp: null
          },
          to: {
            showDatePicker: false,
            showTimePicker: false,
            timestamp: null
          }
        }
      },
      baseURL: process.env.VUE_APP_BASE_URL
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(120)
        },
        place: {
          required,
          maxLength: maxLength(120)
        },
        country: { required },
        date: {
          multiDay: {},
          from: {
            timestamp: {
              required,
              minValue: this.editing ? true : minDate(this.today)
            }
          },
          to: {
            timestamp: {
              required: requiredIf(function() {
                return this.form.date.multiDay;
              }),
              minValue: this.form.date.multiDay
                ? minDate(this.dayAfterEventStartDate)
                : true,
              maxDuration: this.form.date.multiDay
                ? maxDuration(this.form.date.from.timestamp, 10)
                : true
            }
          }
        },
        publicResult: {},
        registration: {
          public: {},
          from: {
            timestamp: {
              required: requiredIf(function() {
                return this.form.registration.public;
              })
            }
          },
          to: {
            timestamp: {
              required: requiredIf(function() {
                return this.form.registration.public;
              }),
              minValue: this.form.registration.public
                ? minDate(this.form.registration.from.timestamp)
                : true
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      getItemsByLang: "nations/getItemsByLang"
    }),
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required &&
        errors.push(this.$i18n.t("forms.event.errors.name.required"));
      !this.$v.form.name.maxLength &&
        errors.push(
          this.$i18n.t("forms.event.errors.name.maxLength", { length: 120 })
        );
      return errors;
    },
    placeErrors() {
      const errors = [];
      if (!this.$v.form.place.$dirty) return errors;
      !this.$v.form.place.required &&
        errors.push(this.$i18n.t("forms.event.errors.place.required"));
      !this.$v.form.place.maxLength &&
        errors.push(
          this.$i18n.t("forms.event.errors.place.maxLength", { length: 120 })
        );
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.form.country.$dirty) return errors;
      !this.$v.form.country.required &&
        errors.push(this.$i18n.t("forms.event.errors.country.required"));
      return errors;
    },
    dateFromErrors() {
      const errors = [];
      if (!this.$v.form.date.from.timestamp.$dirty) return errors;
      !this.$v.form.date.from.timestamp.minValue &&
        errors.push(this.$i18n.t("forms.event.errors.eventStartDate.minValue"));
      return errors;
    },
    dateToErrors() {
      const errors = [];
      if (!this.$v.form.date.to.timestamp.$dirty) return errors;
      !this.$v.form.date.to.timestamp.minValue &&
        errors.push(this.$i18n.t("forms.event.errors.eventEndDate.minValue"));
      !this.$v.form.date.to.timestamp.maxDuration &&
        errors.push(
          this.$i18n.t("forms.event.errors.eventEndDate.maxDuration", {
            numberOfDays: 10
          })
        );
      return errors;
    },
    publicRegistrationToErrors() {
      const errors = [];
      if (!this.$v.form.registration.to.timestamp.$dirty) return errors;
      !this.$v.form.registration.to.timestamp.minValue &&
        errors.push(
          this.$i18n.t("forms.event.errors.registrationEndDate.minValue")
        );
      return errors;
    },
    today() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return now.getTime();
    },
    dayAfterEventStartDate() {
      const startDate = new Date(this.form.date.from.timestamp);
      startDate.setDate(startDate.getDate() + 1);
      startDate.setHours(0, 0, 0, 0);
      return startDate.getTime();
    }
  },
  watch: {
    currentStepNumber(newCurrentStepNumber, oldCurrentStepNumber) {
      if (newCurrentStepNumber && newCurrentStepNumber === this.stepNumber) {
        this.assignData();
      }
    }
  },
  methods: {
    eventFromDatePickerHandler(date) {
      this.$v.form.date.$touch();

      this.form.date.from.timestamp = this.parseDate(date);

      if (!this.$v.form.date.$invalid) {
        this.$emit("dateChanged");
      }
    },
    eventToDatePickerHandler(date) {
      this.$v.form.date.$touch();

      this.form.date.to.timestamp = this.parseDate(date);

      if (!this.$v.form.date.$invalid) {
        this.$emit("dateChanged");
      }
    },
    registrationFromDatePickerHandler(value) {
      this.$v.form.registration.$touch();

      this.form.registration.from.showDatePicker = false;
      this.form.registration.from.timestamp = this.parseDateToTimestamp(
        value,
        this.form.registration.from.timestamp
      );
    },
    registrationFromTimePickerHandler(value) {
      this.$v.form.registration.$touch();

      this.form.registration.from.timestamp = this.parseTimeToTimestamp(
        value,
        this.form.registration.from.timestamp
      );
    },
    registrationToDatePickerHandler(value) {
      this.$v.form.registration.$touch();

      this.form.registration.to.showDatePicker = false;
      this.form.registration.to.timestamp = this.parseDateToTimestamp(
        value,
        this.form.registration.to.timestamp
      );
    },
    registrationToTimePickerHandler(value) {
      this.$v.form.registration.$touch();

      this.form.registration.to.timestamp = this.parseTimeToTimestamp(
        value,
        this.form.registration.to.timestamp
      );
    },
    parseDate(date) {
      if (!date) {
        return null;
      }

      const newDate = date.split("-");
      const year = Number(newDate[0]);
      const monthIndex = Number(newDate[1]) - 1;
      const day = Number(newDate[2]);

      return new Date(year, monthIndex, day).getTime();
    },
    parseDateToTimestamp(date, curTimestamp) {
      const curTime = new Date(curTimestamp);
      const hours = curTime.getHours();
      const minutes = curTime.getMinutes();

      const newDate = date.split("-");
      const year = Number(newDate[0]);
      const monthIndex = Number(newDate[1]) - 1;
      const day = Number(newDate[2]);

      return new Date(year, monthIndex, day, hours, minutes).getTime();
    },
    parseTimeToTimestamp(time, curTimestamp) {
      const curDate = new Date(curTimestamp);
      const year = curDate.getFullYear();
      const monthIndex = curDate.getMonth();
      const day = curDate.getDate();

      const newTime = time.split(":");
      const hours = newTime[0];
      const minutes = newTime[1];

      return new Date(year, monthIndex, day, hours, minutes).getTime();
    },
    getCountryItem(item) {
      return this.$i18n.t(`countries.${item.id}`);
    },
    fileInputHandler(file) {
      this.$v.form.$touch();
      this.form.image.loading = true;

      if (file) {
        this.getFileFromInput(file);
      } else {
        this.clearCanvas("eventImagePreview");
        this.form.image.loading = false;
        this.form.image.data = null;
      }
    },
    getFileFromInput(file) {
      const self = this;
      const image = new Image();
      const reader = new FileReader();

      reader.onload = function(e) {
        image.src = e.target.result;
      };

      reader.onerror = function(event) {
        self.form.image.loading = false;
        console.error(
          "File could not be read! Code " + event.target.error.code
        );
      };

      image.onload = function() {
        self.setCanvas("eventImagePreview", image, 300, 600);
      };

      reader.readAsDataURL(file);
    },
    getFileFromURL(imageUrl) {
      this.form.image.loading = true;

      const self = this;
      const req = new XMLHttpRequest();

      req.open("GET", `${this.baseURL}/${imageUrl}`, true);
      req.responseType = "blob";
      req.onload = function() {
        const blob = req.response;
        blob.name = "banner.jpg";
        createImageBitmap(blob).then(image => {
          self.form.image.file = blob;
          self.setCanvas("eventImagePreview", image, 300, 600);
        });
      };
      req.send();
    },
    setCanvas(canvasId, image, maxHeight = 300, maxWidth = 300) {
      const canvas = document.getElementById(canvasId);
      const context = canvas.getContext("2d");

      let imageHeight = image.height;
      let imageWidth = image.width;

      if (imageHeight > maxHeight) {
        const ratio = maxHeight / imageHeight;
        imageHeight = imageHeight * ratio;
        imageWidth = imageWidth * ratio;
      }

      if (imageWidth > maxWidth) {
        const ratio = maxWidth / imageWidth;
        imageHeight = imageHeight * ratio;
        imageWidth = imageWidth * ratio;
      }

      canvas.height = imageHeight;
      canvas.width = imageWidth;

      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, imageWidth, imageHeight);

      this.form.image.data = canvas.toDataURL("image/jpeg", 1.0);
      this.form.image.loading = false;
    },
    clearCanvas(id) {
      const canvas = document.getElementById(id);
      const context = canvas.getContext("2d");

      context.clearRect(0, 0, canvas.width, canvas.height);
      canvas.height = 0;
      canvas.width = 0;
    },
    assignData() {
      Object.assign(this.form, this.wizardData.event);
    },
    submit() {
      this.$v.$touch();
      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve({
            step: "event",
            form: this.form
          });
        } else {
          reject({
            step: "event",
            form: this.form
          });
        }
      });
    }
  },
  created() {
    this.assignData();

    if (this.event && this.event.imageUrl) {
      this.getFileFromURL(this.event.imageUrl);
    }
  },
  activated() {
    this.assignData();
  }
};
</script>
