<template>
  <v-card flat>
    <div v-for="channel in channels" :key="channel.name">
      <PanelDevicesEditTriggerChannel
        :device="device"
        :channel="channel"
      ></PanelDevicesEditTriggerChannel>
    </div>
  </v-card>
</template>

<script>
/* eslint-disable */

import PanelDevicesEditTriggerChannel from "@/components/PanelDevicesEditTriggerChannel";

export default {
  name: "PanelDevicesEditTrigger",
  components: {
    PanelDevicesEditTriggerChannel
  },
  props: {
    device: {
      type: Object,
      required: true
    },
    channels: {
      type: Array,
      required: true
    }
  }
};
</script>
