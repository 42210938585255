<template>
  <div>
    <v-card-title class="pb-0">
      {{ $t("forms.channel.status") }}

      <v-tooltip v-if="!isFirmwareSupported" top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="error" class="mx-1">mdi-cancel</v-icon>
        </template>
        <span>{{
          $t("devices.firmwareNotSupported", {
            firmwareVersion: minFirmwareVersion
          })
        }}</span>
      </v-tooltip>

      <v-progress-circular
        v-if="form.isLoading"
        :size="14"
        :width="1"
        indeterminate
        class="ml-2"
      />

      <v-icon v-if="form.success" color="success" class="ml-1"
        >mdi-check</v-icon
      >

      <v-icon v-if="form.error" color="error" class="ml-1">mdi-close</v-icon>
    </v-card-title>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-radio-group
            v-model="form.status"
            :readonly="form.isLoading"
            :disabled="!isFirmwareSupported"
            @change="submitDeviceChannelStatusUpdate"
          >
            <v-radio
              v-for="status in statuses"
              :key="status.value"
              :label="$t(status.text)"
              :value="status.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */

import { mapActions } from "vuex";

export default {
  name: "PanelDevicesEditChannelStatus",
  props: {
    device: {
      type: Object,
      required: true
    },
    minFirmwareVersion: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        status: null,
        isLoading: false,
        success: null,
        error: null
      },
      statuses: [
        {
          value: "ACTIVE",
          text: "forms.channel.active"
        },
        {
          value: "BLOCKED",
          text: "forms.channel.blocked"
        },
        {
          value: "DISABLED",
          text: "forms.channel.disabled"
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      setItemChannelStatus: "devices/setItemChannelStatus"
    }),
    initData() {
      const c0Activated = this.device.deviceComponents.ChannelSettings
        .c0Activated;
      const c0Blocked = this.device.deviceComponents.ChannelSettings.c0Blocked;

      this.form.status = this.getDeviceChannelStatus(c0Activated, c0Blocked);
    },
    getDeviceChannelStatus(c0Activated, c0Blocked) {
      if (c0Activated && !c0Blocked) {
        return "ACTIVE";
      } else if (c0Activated && c0Blocked) {
        return "BLOCKED";
      } else if (!c0Activated) {
        return "DISABLED";
      }
    },
    getActivateStatus(status) {
      if (status === "ACTIVE" || status === "BLOCKED") {
        return true;
      }

      return false;
    },
    getBlockStatus(status) {
      if (status === "BLOCKED") {
        return true;
      }

      return false;
    },
    submitDeviceChannelStatusUpdate() {
      this.form.isLoading = true;
      this.form.success = false;
      this.form.error = false;

      const self = this;
      const payload = {
        deviceId: this.device.id,
        data: {
          activated: this.getActivateStatus(this.form.status),
          blocked: this.getBlockStatus(this.form.status)
        }
      };

      this.setItemChannelStatus(payload)
        .then(() => {
          this.form.isLoading = false;
          this.form.success = true;
          setTimeout(() => {
            self.form.success = false;
          }, 2000);
        })
        .catch(() => {
          this.form.isLoading = false;
          this.initData();
          this.form.error = true;
          setTimeout(() => {
            self.form.error = false;
          }, 2000);
        });
    }
  },
  computed: {
    isFirmwareSupported() {
      const deviceFirmwareVersion = this.device.deviceComponents.DeviceInfo.firmwareVersion.replace(
        ".",
        ""
      );
      const minFirmwareVersion = this.$props.minFirmwareVersion.replace(
        ".",
        ""
      );

      return (
        parseInt(deviceFirmwareVersion, 16) >= parseInt(minFirmwareVersion, 16)
      );
    }
  },
  created() {
    this.initData();
  }
};
</script>
