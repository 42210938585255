<template>
  <v-form @submit.prevent="submitForm" class="mb-6">
    <div class="d-flex justify-space-between align-baseline">
      <v-select
        :label="$t('forms.deviceTimezone.timezone')"
        name="timezone"
        :items="timezones"
        v-model="form.timezone"
        suffix="Hours"
        :error-messages="timezoneErrors"
      ></v-select>
      <v-btn
        type="submit"
        color="success"
        class="ml-8"
        elevation="2"
        :loading="loading"
        :fab="$vuetify.breakpoint.name === 'xs'"
        :small="$vuetify.breakpoint.name === 'xs'"
      >
        {{ $vuetify.breakpoint.smAndUp ? $t("forms.deviceTimezone.submit") : ""
        }}<v-icon :right="$vuetify.breakpoint.smAndUp">mdi-content-save</v-icon>
      </v-btn>
    </div>
    <v-alert v-if="error" class="mt-4 mb-8" dense outlined type="error">
      {{ $t(error) }}
    </v-alert>
    <v-alert
      v-else-if="success"
      class="mt-4 mb-8"
      dense
      outlined
      type="success"
    >
      {{ $t(success) }}
    </v-alert>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PanelDevicesEditTimezone",
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        timezone: this.device.deviceComponents.TimingInfo.timeOffsetUTC !== null ? this.device.deviceComponents.TimingInfo.timeOffsetUTC : null
      },
      timezones: [
        { value: -720, text: "-12" },
        { value: -690, text: "-11.5" },
        { value: -660, text: "-11" },
        { value: -630, text: "-10.5" },
        { value: -600, text: "-10" },
        { value: -570, text: "-9.5" },
        { value: -540, text: "-9" },
        { value: -510, text: "-8.5" },
        { value: -480, text: "-8" },
        { value: -450, text: "-7.5" },
        { value: -420, text: "-7" },
        { value: -390, text: "-6.5" },
        { value: -360, text: "-6" },
        { value: -330, text: "-5.5" },
        { value: -300, text: "-5" },
        { value: -270, text: "-4.5" },
        { value: -240, text: "-4" },
        { value: -210, text: "-3.5" },
        { value: -180, text: "-3" },
        { value: -150, text: "-2.5" },
        { value: -120, text: "-2" },
        { value: -90, text: "-1.5" },
        { value: -60, text: "-1" },
        { value: 0, text: "0" },
        { value: 60, text: "+1" },
        { value: 90, text: "+1.5" },
        { value: 120, text: "+2" },
        { value: 150, text: "+2.5" },
        { value: 180, text: "+3" },
        { value: 210, text: "+3.5" },
        { value: 240, text: "+4" },
        { value: 270, text: "+4.5" },
        { value: 300, text: "+5" },
        { value: 330, text: "+5.5" },
        { value: 360, text: "+6" },
        { value: 390, text: "+6.5" },
        { value: 420, text: "+7" },
        { value: 450, text: "+7.5" },
        { value: 480, text: "+8" },
        { value: 510, text: "+8.5" },
        { value: 540, text: "+9" },
        { value: 570, text: "+9.5" },
        { value: 600, text: "+10" },
        { value: 630, text: "+10.5" },
        { value: 660, text: "+11" },
        { value: 690, text: "+11.5" },
        { value: 720, text: "+12" },
        { value: 750, text: "+12.5" },
        { value: 780, text: "+13" },
        { value: 810, text: "+13.5" },
        { value: 840, text: "+14" },
      ],
      loading: false,
      success: null,
      error: null
    };
  },
  validations: {
    form: {
      timezone: { required }
    }
  },
  computed: {
    timezoneErrors() {
      const errors = [];
      if (!this.$v.form.timezone.$dirty) return errors;
      !this.$v.form.timezone.required && errors.push(this.$i18n.t("forms.deviceTimezone.errors.timezone.required"));
      return errors;
    }
  },
  methods: {
    ...mapActions({
      updateItemTimezone: "devices/updateItemTimezone"
    }),
    initData() {
      this.form.timezone = this.device.deviceComponents.TimingInfo.timeOffsetUTC !== null ? this.device.deviceComponents.TimingInfo.timeOffsetUTC : null;
    },
    submitForm() {
      this.$v.form.$touch();
      this.success = null;
      this.error = null;

      if (!this.$v.form.$invalid && !this.loading) {
        this.loading = true;

        const payload = {
          deviceId: this.device.id,
          data: {
            value: this.form.timezone
          }
        };

        this.updateItemTimezone(payload)
          .then(response => {
            this.loading = false;
            this.success = "forms.deviceTimezone.success";
          })
          .catch(response => {
            this.loading = false;

            switch (response.data.status) {
              case -2001:
                this.error = "errors.device.updateTimeout";
                break;
              case -2008:
                this.error = "errors.device.communicationFailed";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
          });
      }
    }
  }
};
</script>
