<template>
  <v-dialog v-model="show" width="400" persistent>
    <v-card>
      <v-card-title>
        {{ $t("forms.confirmation.title") }}
      </v-card-title>

      <slot></slot>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn ref="continue" color="primary" text>
          {{ $t("forms.confirmation.continue") }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn ref="cancel" color="error" text>
          {{ $t("forms.confirmation.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Confirmation",
  data() {
    return {
      show: false
    };
  },
  methods: {
    confirm() {
      return new Promise((resolve, reject) => {
        const self = this;
        this.show = true;
        this.$nextTick(() => {
          this.$refs.continue.$on("click", function() {
            self.show = false;
            resolve(true);
          });

          this.$refs.cancel.$on("click", function() {
            self.show = false;
            resolve(false);
          });
        });
      });
    }
  }
};
</script>
