<template>
  <div>
    <v-card-title>
      {{ $t("forms.device.channelTrigger", { channel: channel.name }) }}
      <v-btn
        small
        color="error"
        class="ml-auto"
        :loading="loading"
        :disabled="loading"
        @click="handleTriggerDelete"
      >
        {{ $t("forms.device.delete") }}
        <v-icon small class="ml-1">mdi-delete</v-icon>
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredTrigger"
      :loading="loading"
      mobile-breakpoint="0"
      disable-pagination
      hide-default-footer
      disable-sort
      show-expand
    >
      <template v-slot:item.blocked="{ item }">
        {{ getTriggerStatus(item) }}
      </template>

      <template v-slot:item.startNumber.type="{ item }">
        {{ $t(`trigger.table.${String(item.startNumber.type).toLowerCase()}`) }}
      </template>

      <template v-slot:item.timestamp="{ item }">
        <span style="white-space:nowrap">
          {{ formatTimestamp_100ns(item.timestamp, item.timeOffset) }}
        </span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <div class="subtitle font-weight-bold">
                  {{ $t("trigger.table.timestamp") }}
                </div>
                <div>{{ item.timestamp }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="subtitle font-weight-bold">
                  {{ $t("trigger.table.offset") }}
                </div>
                <div>{{ item.timeOffset }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="subtitle font-weight-bold">
                  {{ $t("trigger.table.type") }}
                </div>
                <div>{{ item.type }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="subtitle font-weight-bold">
                  {{ $t("trigger.table.valid") }}
                </div>
                <div>
                  <v-icon>{{ validIcon(item.valid) }}</v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <v-card-actions
      v-if="hasPrevPage || hasNextPage"
      class="d-flex justify-space-between"
    >
      <v-btn
        icon
        tile
        color="primary"
        :disabled="!hasPrevPage"
        @click="prevPage"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="grey--text text--darken-2 font-weight-bold">
        {{ currentPage + 1 }}
      </span>

      <v-btn
        icon
        tile
        color="primary"
        :disabled="!hasNextPage"
        @click="nextPage"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
/* eslint-disable */

import { mapActions } from "vuex";
import formatTimestamp from "@/mixins/formatTimestamp";

export default {
  name: "PanelDevicesEditTrigger",
  props: {
    device: {
      type: Object,
      required: true
    },
    channel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      currentPage: 0,
      trigger: []
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("trigger.table.status"),
          value: "blocked"
        },
        {
          text: this.$i18n.t("trigger.table.startNumber"),
          value: "startNumber.startNumber"
        },
        {
          text: this.$i18n.t("trigger.table.type"),
          value: "startNumber.type"
        },
        {
          text: this.$i18n.t("trigger.table.timestamp"),
          value: "timestamp"
        },
        {
          value: "data-table-expand"
        }
      ];
    },
    filteredTrigger() {
      return this.trigger
        ? this.trigger
            .slice(0, this.itemsPerPage)
            .map((trigger, index) => ({ ...trigger, id: index }))
        : null;
    },
    hasPrevPage() {
      return this.currentPage !== 0;
    },
    hasNextPage() {
      return this.itemsPerPage < this.trigger.length;
    }
  },
  mixins: [formatTimestamp],
  methods: {
    ...mapActions({
      fetchTriggersByDeviceChannel: "devices/fetchTriggersByDeviceChannel",
      deleteTriggersByChannel: "devices/deleteTriggersByChannel"
    }),
    handleTriggerDelete() {
      const payload = {
        deviceId: this.device.id,
        channelId: this.channel.name.substr(1, 1)
      };

      if (confirm(this.$i18n.t("forms.device.triggers.deleteConfirmation"))) {
        this.loading = true;
        this.deleteTriggersByChannel(payload)
          .then(() => {
            this.fetchPage();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    prevPage() {
      this.currentPage -= 1;
      this.fetchPage();
    },
    nextPage() {
      this.currentPage += 1;
      this.fetchPage();
    },
    fetchPage() {
      this.loading = true;

      const payload = {
        deviceId: this.device.id,
        channelId: this.channel.name.substr(1, 1),
        query: {
          offset: this.itemsPerPage * this.currentPage,
          limit: this.itemsPerPage + 1
        }
      };

      this.fetchTriggersByDeviceChannel(payload)
        .then(response => {
          this.trigger = response.data.data;
          this.loading = false;
        })
        .catch(response => {
          this.loading = false;
        });
    },
    validIcon(valid) {
      if (valid) return "mdi-check";

      return "mdi-close";
    },
    getTriggerStatus(trigger) {
      if (trigger.type === "ClearTrigger") {
        return this.$i18n.t("trigger.table.deleted");
      }
      return trigger.blocked
        ? this.$i18n.t("trigger.table.blocked")
        : this.$i18n.t("trigger.table.active");
    }
  },
  created() {
    this.fetchPage();
  },
  mounted() {
    this.$store.subscribe(mutation => {
      const whitelist = ["trigger/addItem"];

      if (whitelist.includes(mutation.type)) {
        const trigger = mutation.payload;

        if (
          trigger.deviceId === this.device.id &&
          trigger.timingChannel === this.channel.name
        ) {
          this.trigger.unshift(trigger);
          this.trigger.splice(11, 1);
        }
      }
    });
  }
};
</script>
