<template>
  <v-timeline-item
    :class="!isStart && !isFinish ? 'sortable_item' : ''"
    color="primary"
    fill-dot
    :icon="isStart || isFinish ? 'mdi-flag-outline' : ''"
    :small="!isStart && !isFinish"
  >
    <v-card>
      <div class="d-flex justify-space-between">
        <v-card-title v-if="isStart">
          {{ $t("forms.event.start") }}
        </v-card-title>
        <v-card-title v-else-if="isFinish">
          {{ $t("forms.event.finish") }}
        </v-card-title>
        <v-card-title v-else>
          {{ $t("forms.event.measuringPoint", { number: position + 1 }) }}
        </v-card-title>
        <v-card-title v-if="!isStart && !isFinish">
          <v-btn @click="$emit('removed', data.id)" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-title>
      </div>
      <div class="deviceWrapper">
        <div class="pa-4 d-inline-flex flex-wrap">
          <PanelDevicesEditDialog
            v-for="item in addedDevices"
            :key="item.id"
            :id="data.id"
            :index="item.deviceIndex"
            :item="item"
            @channelChanged="changeChannel"
            @removedDevice="removeDevice"
          />
          <span v-if="devices">
            <v-dialog v-model="showDialog">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" color="primary" class="ma-1">
                  {{ $t("forms.event.addDevice") }}
                </v-chip>
              </template>

              <v-card>
                <v-toolbar flat>
                  <v-toolbar-title>
                    {{ $t("devices.title") }}
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn icon @click="showDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-divider></v-divider>

                <v-data-table
                  v-if="devices"
                  :headers="headers"
                  :items="devices"
                  :sort-by="['id']"
                  mobile-breakpoint="0"
                  disable-pagination
                  hide-default-footer
                >
                  <template
                    v-slot:item.deviceComponents.MobileInfo.signalStrength="{
                      item
                    }"
                  >
                    <v-icon>
                      {{
                        signalStrengthIcon(
                          item.deviceComponents.MobileInfo.signalStrength
                        )
                      }}
                    </v-icon>
                  </template>
                  <template
                    v-slot:item.deviceComponents.MobileInfo.operatorName="{
                      item
                    }"
                  >
                    <span
                      v-if="
                        item.deviceComponents.MobileInfo.operatorName &&
                          item.deviceComponents.MobileInfo.radioAccessTechnology
                      "
                    >
                      {{
                        `${item.deviceComponents.MobileInfo.operatorName} (${item.deviceComponents.MobileInfo.radioAccessTechnology})`
                      }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn @click="addDevice(item)" icon>
                      <v-icon color="primary">mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <v-card-text v-else>
                  {{ $t("forms.event.noDevices") }}
                </v-card-text>
              </v-card>
            </v-dialog>
          </span>
          <v-card-subtitle v-else>{{
            $t("forms.event.noDevices")
          }}</v-card-subtitle>
        </div>
      </div>

      <div v-if="!isStart" class="px-4">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <h4>
                {{ $t("forms.event.speedToPreviousMeasuringPoint") }}
              </h4>
            </v-col>

            <v-col cols="12" sm="6">
              <v-checkbox
                :label="$t('forms.event.enableSpeed')"
                v-model="data.speedMeasurementEnabled"
                :disabled="speedEvent"
              ></v-checkbox>
            </v-col>

            <v-col v-if="data.speedMeasurementEnabled" cols="12" sm="6">
              <v-text-field
                type="number"
                step="0.01"
                :label="$t('forms.event.distance')"
                v-model.number="validations.speedMeasurementDistance.$model"
                suffix="m"
                :error-messages="distanceErrors"
              ></v-text-field>
            </v-col>

            <v-col v-if="data.speedMeasurementEnabled" cols="12" sm="6">
              <v-text-field
                type="number"
                step="0.1"
                :label="$t('forms.event.minSpeed')"
                v-model.number="validations.speedMeasurementMinSpeed.$model"
                :error-messages="minSpeedErrors"
                class="speedValue"
              >
                <template v-slot:append>
                  <v-select
                    :items="speedUnits"
                    v-model="speedUnit"
                    hide-details
                    solo
                    flat
                    class="speedUnit"
                  ></v-select>
                </template>
              </v-text-field>
            </v-col>

            <v-col v-if="data.speedMeasurementEnabled" cols="12" sm="6">
              <v-text-field
                type="number"
                step="0.1"
                :label="$t('forms.event.maxSpeed')"
                v-model.number="validations.speedMeasurementMaxSpeed.$model"
                :error-messages="maxSpeedErrors"
                class="speedValue"
              >
                <template v-slot:append>
                  <v-select
                    :items="speedUnits"
                    v-model="speedUnit"
                    hide-details
                    solo
                    flat
                    class="speedUnit"
                  ></v-select>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-expand-transition>
        <div v-if="tolerancesEnabled && !isStart" class="px-4">
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <h4 class="mb-4">
                  {{ $t("forms.event.referenceRun") }}
                </h4>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.number="validations.toleranceLowerBound.$model"
                  :label="$t('forms.event.lowerBound')"
                  :error-messages="lowerBoundErrors"
                  class="boundValue"
                >
                  <template v-slot:append>
                    <v-select
                      :items="boundTypes"
                      v-model="data.toleranceIsLowerPercentage"
                      hide-details
                      solo
                      flat
                      class="boundType"
                    ></v-select>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.number="validations.toleranceUpperBound.$model"
                  :label="$t('forms.event.upperBound')"
                  :error-messages="upperBoundErrors"
                  class="boundValue"
                >
                  <template v-slot:append>
                    <v-select
                      :items="boundTypes"
                      v-model="data.toleranceIsUpperPercentage"
                      hide-details
                      solo
                      flat
                      class="boundType"
                    ></v-select>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-expand-transition>
    </v-card>
  </v-timeline-item>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters } from "vuex";
import PanelDevicesEditDialog from "@/components/PanelDevicesEditDialog";

export default {
  name: "MeasuringPoint",
  components: {
    PanelDevicesEditDialog
  },
  props: {
    position: {
      type: Number,
      required: true
    },
    length: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    setupSpeedUnit: {
      type: String,
      required: true
    },
    tolerancesEnabled: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      required: true
    },
    speedEvent: {
      type: Boolean
    }
  },
  data() {
    return {
      showDialog: false,
      boundTypes: [
        {
          text: "%",
          value: true
        },
        {
          text: "s",
          value: false
        }
      ],
      speedUnits: [
        {
          text: "mph",
          value: "MP_H"
        },
        {
          text: "km/h",
          value: "KM_H"
        },
        {
          text: "m/s",
          value: "M_S"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      devices: state => state.devices.items
    }),
    ...mapGetters({
      getDeviceById: "devices/getItemById"
    }),
    isStart() {
      return this.position === 0;
    },
    isFinish() {
      return this.position === this.length - 1;
    },
    speedUnit: {
      get() {
        return this.setupSpeedUnit;
      },
      set(value) {
        this.$emit("speedUnitUpdated", value);
      }
    },
    headers() {
      return [
        {
          text: this.$i18n.t("devices.table.id"),
          value: "id"
        },
        {
          text: this.$i18n.t("devices.table.name"),
          value: "deviceComponents.AppSettings.name"
        },
        {
          text: this.$i18n.t("devices.table.firmware"),
          value: "deviceComponents.DeviceInfo.firmwareVersion"
        },
        {
          text: this.$i18n.t("devices.table.operator"),
          value: "deviceComponents.MobileInfo.operatorName"
        },
        {
          text: this.$i18n.t("devices.table.signalStrength"),
          value: "deviceComponents.MobileInfo.signalStrength"
        },
        {
          value: "actions",
          sortable: false,
          width: "1%"
        }
      ];
    },
    lowerBoundErrors() {
      const errors = [];
      if (!this.validations.toleranceLowerBound.$dirty) return errors;
      !this.validations.toleranceLowerBound.required &&
        errors.push(this.$i18n.t("forms.event.errors.lowerBound.required"));
      !this.validations.toleranceLowerBound.numeric &&
        errors.push(this.$i18n.t("forms.event.errors.lowerBound.numeric"));
      return errors;
    },
    upperBoundErrors() {
      const errors = [];
      if (!this.validations.toleranceUpperBound.$dirty) return errors;
      !this.validations.toleranceUpperBound.required &&
        errors.push(this.$i18n.t("forms.event.errors.upperBound.required"));
      !this.validations.toleranceUpperBound.numeric &&
        errors.push(this.$i18n.t("forms.event.errors.upperBound.numeric"));
      return errors;
    },
    distanceErrors() {
      const errors = [];
      if (!this.validations.speedMeasurementDistance.$dirty) return errors;
      !this.validations.speedMeasurementDistance.required &&
        errors.push(this.$i18n.t("forms.event.errors.distance.required"));
      !this.validations.speedMeasurementDistance.maxDecimals &&
        errors.push(
          this.$i18n.t("forms.event.errors.distance.maxDecimals", { number: 2 })
        );
      return errors;
    },
    minSpeedErrors() {
      const errors = [];
      if (!this.validations.speedMeasurementMinSpeed.$dirty) return errors;
      !this.validations.speedMeasurementMinSpeed.required &&
        errors.push(this.$i18n.t("forms.event.errors.minSpeed.required"));
      !this.validations.speedMeasurementMinSpeed.maxDecimals &&
        errors.push(
          this.$i18n.t("forms.event.errors.minSpeed.maxDecimals", { number: 1 })
        );
      return errors;
    },
    maxSpeedErrors() {
      const errors = [];
      if (!this.validations.speedMeasurementMaxSpeed.$dirty) return errors;
      !this.validations.speedMeasurementMaxSpeed.required &&
        errors.push(this.$i18n.t("forms.event.errors.maxSpeed.required"));
      !this.validations.speedMeasurementMaxSpeed.maxDecimals &&
        errors.push(
          this.$i18n.t("forms.event.errors.maxSpeed.maxDecimals", { number: 1 })
        );
      return errors;
    },
    addedDevices() {
      return this.data.devices.reduce((re, el, index) => {
        const device = this.getDeviceById(el.deviceId);

        if (device !== null) {
          re.push({
            device,
            channelIndex: el.channelIndex,
            deviceIndex: index
          });
        }

        return re;
      }, []);
    }
  },
  methods: {
    signalStrengthIcon(signalStrength) {
      if (signalStrength > 66) {
        return "mdi-signal-cellular-3";
      } else if (signalStrength > 33) {
        return "mdi-signal-cellular-2";
      } else if (signalStrength > 0) {
        return "mdi-signal-cellular-1";
      }

      return "mdi-signal-cellular-outline";
    },
    addDevice(device) {
      this.showDialog = false;
      this.$emit("addedDevice", this.data.id, device.id);
    },
    removeDevice(index) {
      this.$emit("removedDevice", this.data.id, index);
    },
    changeChannel(id, item, index) {
      this.$emit("channelChanged", id, item, index);
    }
  }
};
</script>
<style>
.deviceWrapper {
  overflow: auto;
}
.boundType {
  max-width: 70px;
}
.speedUnit {
  max-width: 95px;
}
.boundType > .v-input__control,
.speedUnit > .v-input__control {
  min-height: 0 !important;
}
.boundType,
.boundValue,
.speedUnit,
.speedValue {
  padding-top: 0;
  margin-top: 0;
}
</style>
