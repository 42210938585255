import { helpers } from "vuelidate/lib/validators";
import moment from "moment";

const maxDuration = (referenceValue, maxDays) => value => {
  const from = moment(referenceValue);
  const to = moment(value);
  const diff = to.diff(from, "days");

  return !helpers.req(value) || Math.abs(diff) <= maxDays;
};

export default maxDuration;
