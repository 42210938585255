<template>
  <div>
    <v-card-title class="pb-0">
      {{ $t("forms.channel.title", { channel: channel.name }) }}
    </v-card-title>
    <v-container>
      <v-row dense align="center">
        <v-col cols="12">
          <v-form @submit.prevent="submitFormTimeDelay" class="pt-4">
            <div class="d-flex justify-space-between align-center">
              <v-text-field
                :label="$t('forms.channel.timeDelay.label')"
                name="timeDelay"
                type="number"
                step="0.01"
                min="0.01"
                max="59.99"
                :suffix="$t('forms.channel.seconds')"
                v-model.number="formTimeDelay.timeDelay"
                :error-messages="timeDelayErrors"
              ></v-text-field>
              <v-btn
                type="submit"
                color="success"
                class="ml-8"
                elevation="2"
                :loading="formTimeDelay.loading"
                :fab="$vuetify.breakpoint.name === 'xs'"
                :small="$vuetify.breakpoint.name === 'xs'"
              >
                {{
                  $vuetify.breakpoint.smAndUp ? $t("forms.channel.submit") : ""
                }}<v-icon :right="$vuetify.breakpoint.smAndUp"
                  >mdi-content-save</v-icon
                >
              </v-btn>
            </div>

            <v-alert
              v-if="formTimeDelay.error"
              class="my-4"
              dense
              outlined
              type="error"
            >
              {{ $t(formTimeDelay.error) }}
            </v-alert>
            <v-alert
              v-else-if="formTimeDelay.success"
              class="my-4"
              dense
              outlined
              type="success"
            >
              {{ $t(formTimeDelay.success) }}
            </v-alert>
          </v-form>
        </v-col>
        <v-col cols="12">
          <v-form @submit.prevent="submitformPulseHoldTime">
            <div class="d-flex justify-space-between align-center">
              <v-text-field
                :label="$t('forms.channel.pulseHoldTime.label')"
                name="pulseHoldTime"
                type="number"
                step="0.1"
                min="0"
                max="59.9"
                :suffix="$t('forms.channel.seconds')"
                v-model.number="formPulseHoldTime.pulseHoldTime"
                :error-messages="pulseHoldTimeErrors"
              ></v-text-field>
              <v-btn
                type="submit"
                color="success"
                class="ml-8"
                elevation="2"
                :loading="formPulseHoldTime.loading"
                :fab="$vuetify.breakpoint.name === 'xs'"
                :small="$vuetify.breakpoint.name === 'xs'"
              >
                {{
                  $vuetify.breakpoint.smAndUp ? $t("forms.channel.submit") : ""
                }}<v-icon :right="$vuetify.breakpoint.smAndUp"
                  >mdi-content-save</v-icon
                >
              </v-btn>
            </div>

            <v-alert
              v-if="formPulseHoldTime.error"
              class="my-4"
              dense
              outlined
              type="error"
            >
              {{ $t(formPulseHoldTime.error) }}
            </v-alert>
            <v-alert
              v-else-if="formPulseHoldTime.success"
              class="my-4"
              dense
              outlined
              type="success"
            >
              {{ $t(formPulseHoldTime.success) }}
            </v-alert>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */

import { mapActions } from "vuex";
import {
  required,
  decimal,
  minValue,
  maxValue
} from "vuelidate/lib/validators";

export default {
  name: "PanelDevicesEditChannel",
  props: {
    device: {
      type: Object,
      required: true
    },
    channel: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      formTimeDelay: {
        timeDelay: null,
        loading: false,
        success: null,
        error: null
      },
      formPulseHoldTime: {
        pulseHoldTime: null,
        loading: false,
        success: null,
        error: null
      }
    };
  },
  validations: {
    formTimeDelay: {
      timeDelay: {
        required,
        decimal,
        minValue: minValue(0.01),
        maxValue: maxValue(59.99)
      }
    },
    formPulseHoldTime: {
      pulseHoldTime: {
        required,
        decimal,
        minValue: minValue(0),
        maxValue: maxValue(59.9)
      }
    }
  },
  computed: {
    timeDelayErrors() {
      const errors = [];
      if (!this.$v.formTimeDelay.timeDelay.$dirty) return errors;
      !this.$v.formTimeDelay.timeDelay.required &&
        errors.push(this.$i18n.t("forms.channel.timeDelay.errors.required"));
      !this.$v.formTimeDelay.timeDelay.decimal &&
        errors.push(this.$i18n.t("forms.channel.timeDelay.errors.numeric"));
      !this.$v.formTimeDelay.timeDelay.minValue &&
        errors.push(
          this.$i18n.t("forms.channel.timeDelay.errors.minValue", {
            number: 0.01
          })
        );
      !this.$v.formTimeDelay.timeDelay.maxValue &&
        errors.push(
          this.$i18n.t("forms.channel.timeDelay.errors.maxValue", {
            number: 59.99
          })
        );
      return errors;
    },
    pulseHoldTimeErrors() {
      const errors = [];
      if (!this.$v.formPulseHoldTime.pulseHoldTime.$dirty) return errors;
      !this.$v.formPulseHoldTime.pulseHoldTime.required &&
        errors.push(
          this.$i18n.t("forms.channel.pulseHoldTime.errors.required")
        );
      !this.$v.formPulseHoldTime.pulseHoldTime.decimal &&
        errors.push(this.$i18n.t("forms.channel.pulseHoldTime.errors.numeric"));
      !this.$v.formPulseHoldTime.pulseHoldTime.minValue &&
        errors.push(
          this.$i18n.t("forms.channel.pulseHoldTime.errors.minValue", {
            number: 0
          })
        );
      !this.$v.formPulseHoldTime.pulseHoldTime.maxValue &&
        errors.push(
          this.$i18n.t("forms.channel.pulseHoldTime.errors.maxValue", {
            number: 59.9
          })
        );
      return errors;
    }
  },
  methods: {
    ...mapActions({
      updateItemChannelTimeDelay: "devices/updateItemChannelTimeDelay",
      updateItemChannelPulseHold: "devices/updateItemChannelPulseHold"
    }),
    submitFormTimeDelay() {
      this.$v.formTimeDelay.$touch();
      this.formTimeDelay.success = null;
      this.formTimeDelay.error = null;

      if (!this.$v.formTimeDelay.$invalid && !this.loading) {
        this.formTimeDelay.loading = true;

        const payload = {
          deviceId: this.device.id,
          data: {
            channel: this.channel.name,
            deadTime: this.formTimeDelay.timeDelay * 1000
          }
        };

        this.updateItemChannelTimeDelay(payload)
          .then(() => {
            this.formTimeDelay.loading = false;
            this.formTimeDelay.success = "forms.channel.timeDelay.success";
          })
          .catch(response => {
            this.formTimeDelay.loading = false;

            switch (response.data.status) {
              case -2001:
                this.formTimeDelay.error = "errors.device.updateTimeout";
                break;
              case -2008:
                this.formTimeDelay.error = "errors.device.communicationFailed";
                break;
              case -2010:
                this.formTimeDelay.error =
                  "forms.channel.timeDelay.errors.invalidDeadTime";
                break;
              default:
                this.formTimeDelay.error = "errors.unexpectedError";
            }
          });
      }
    },
    submitformPulseHoldTime() {
      this.$v.formPulseHoldTime.$touch();
      this.formPulseHoldTime.success = null;
      this.formPulseHoldTime.error = null;

      if (!this.$v.formPulseHoldTime.$invalid && !this.loading) {
        this.formPulseHoldTime.loading = true;

        const payload = {
          deviceId: this.device.id,
          data: {
            channel: this.channel.name,
            time: this.formPulseHoldTime.pulseHoldTime * 1000
          }
        };

        this.updateItemChannelPulseHold(payload)
          .then(() => {
            this.formPulseHoldTime.loading = false;
            this.formPulseHoldTime.success =
              "forms.channel.pulseHoldTime.success";
          })
          .catch(response => {
            this.formPulseHoldTime.loading = false;

            switch (response.data.status) {
              case -2001:
                this.formPulseHoldTime.error = "errors.device.updateTimeout";
                break;
              case -2008:
                this.formPulseHoldTime.error =
                  "errors.device.communicationFailed";
                break;
              case -2012:
                this.formPulseHoldTime.error =
                  "forms.channel.pulseHoldTime.errors.invalidPulseHoldTime";
                break;
              default:
                this.formPulseHoldTime.error = "errors.unexpectedError";
            }
          });
      }
    },
    initData() {
      const channel = this.channel.name.toLowerCase();
      this.formTimeDelay.timeDelay =
        this.device.deviceComponents.ChannelSettings[
          `${channel}DeadTime_ms`
        ] === null
          ? 1.0
          : this.device.deviceComponents.ChannelSettings[
              `${channel}DeadTime_ms`
            ] / 1000;
      this.formPulseHoldTime.pulseHoldTime =
        this.device.deviceComponents.ChannelSettings[
          `${channel}PulseHoldTime_ms`
        ] === null
          ? 5.0
          : this.device.deviceComponents.ChannelSettings[
              `${channel}PulseHoldTime_ms`
            ] / 1000;
    }
  },
  created() {
    this.initData();
  }
};
</script>
