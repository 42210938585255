export default {
  MT1: {
    latestFirmwareVersion: "24.61",
    channels: [
      {
        name: "C0",
        color: "green"
      },
      {
        name: "C1",
        color: "red"
      }
    ]
  }
};
