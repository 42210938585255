<template>
  <v-form class="mx-4">
    <v-container>
      <v-row>
        <v-col cols="12" sm="8">
          <v-card-title>
            {{ $t("forms.event.configuration") }}
          </v-card-title>

          <v-sheet v-for="(field, index) in basicFields" :key="field.id">
            <v-row dense class="align-center">
              <v-col cols="6">
                <span>{{ $t(`competitors.tables.${field.id}`) }}</span>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="field.accessControl.competitor.write"
                  @change="$v.form.$touch"
                  class="mt-0"
                  hide-details
                  @click.native="checkFieldVisibility(index)"
                >
                  <template v-slot:label>
                    {{ $t("forms.event.configurations.visible") }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{ $t("forms.event.hints.visible") }}
                    </v-tooltip>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="field.accessControl.other.read"
                  @change="$v.form.$touch"
                  :disabled="
                    field.id === 'startNumber' || field.id === 'email'
                      ? true
                      : !field.accessControl.competitor.write
                  "
                  class="mt-0"
                  hide-details
                  @click.native="checkFieldVisibility(index)"
                >
                  <template v-slot:label>
                    {{ $t("forms.event.configurations.public") }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{ $t("forms.event.hints.public") }}
                    </v-tooltip>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="field.validation.required"
                  @change="$v.form.$touch"
                  :disabled="!field.accessControl.competitor.write"
                  class="mt-0"
                  hide-details
                >
                  <template v-slot:label>
                    {{ $t("forms.event.configurations.required") }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{ $t("forms.event.hints.required") }}
                    </v-tooltip>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-if="
                    field.id !== 'startNumber' &&
                      field.id !== 'lastName' &&
                      field.id !== 'firstName' &&
                      field.id !== 'email'
                  "
                  v-model="field.settings.classDivision"
                  @change="$v.form.$touch"
                  :disabled="!field.accessControl.competitor.write"
                  class="mt-0"
                  hide-details
                >
                  <template v-slot:label>
                    {{ $t("forms.event.configurations.class") }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{ $t("forms.event.hints.class") }}
                    </v-tooltip>
                  </template>
                </v-checkbox>
                <v-select
                  v-if="field.id === 'firstName' || field.id === 'lastName'"
                  :label="$t('forms.event.configurations.format')"
                  :items="formats"
                  v-model="field.view.format"
                  @change="$v.form.$touch"
                  hide-details
                  class="mt-4 mb-2"
                >
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{ $t("forms.event.hints.format") }}
                    </v-tooltip>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>
          </v-sheet>

          <v-sheet v-for="(field, index) in customFields" :key="field.id">
            <v-row dense class="align-center">
              <v-col cols="6">
                <v-text-field
                  :ref="`field-${field.id}`"
                  :label="$t('forms.event.configurations.name')"
                  v-model="field.view.label"
                  @input="$v.form.$touch"
                  class="mr-2"
                  :error-messages="
                    customFieldNameErrors(index + basicFields.length)
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="field.accessControl.other.read"
                  @change="$v.form.$touch"
                  class="mt-0"
                  hide-details
                >
                  <template v-slot:label>
                    {{ $t("forms.event.configurations.public") }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{ $t("forms.event.hints.public") }}
                    </v-tooltip>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="field.validation.required"
                  @change="$v.form.$touch"
                  class="mt-0"
                  hide-details
                >
                  <template v-slot:label>
                    {{ $t("forms.event.configurations.required") }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{ $t("forms.event.hints.required") }}
                    </v-tooltip>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="field.settings.classDivision"
                  @change="$v.form.$touch"
                  class="mt-0"
                  hide-details
                >
                  <template v-slot:label>
                    {{ $t("forms.event.configurations.class") }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small right>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{ $t("forms.event.hints.class") }}
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="text-center">
                <v-row v-if="index !== 0" dense>
                  <v-btn
                    @click="moveRegistrationFieldUp(index + basicFields.length)"
                    icon
                    small
                  >
                    <v-icon small>mdi-arrow-up</v-icon>
                  </v-btn>
                </v-row>
                <v-row dense>
                  <v-btn
                    @click="removeRegistrationField(index + basicFields.length)"
                    icon
                    small
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-row>
                <v-row v-if="index !== customFields.length - 1" dense>
                  <v-btn
                    @click="
                      moveRegistrationFieldDown(index + basicFields.length)
                    "
                    icon
                    small
                  >
                    <v-icon small>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>
          </v-sheet>

          <v-row dense class="justify-center">
            <v-col cols="6">
              <v-btn color="primary" @click="addRegistrationField">
                {{ $t("forms.event.addTextField") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card-title>
            {{ $t("forms.event.preview") }}
          </v-card-title>

          <v-text-field
            v-if="
              form.data[0] &&
                form.data[0].accessControl.competitor.write &&
                whitelist.includes(form.data[0].id)
            "
            name="startNumber"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[0].id}`) }}
              <span v-if="form.data[0].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-text-field
            v-if="
              form.data[1] &&
                form.data[1].accessControl.competitor.write &&
                whitelist.includes(form.data[1].id)
            "
            name="firstName"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[1].id}`) }}
              <span v-if="form.data[1].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-text-field
            v-if="
              form.data[2] &&
                form.data[2].accessControl.competitor.write &&
                whitelist.includes(form.data[2].id)
            "
            name="lastName"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[2].id}`) }}
              <span v-if="form.data[2].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-select
            v-if="
              form.data[3] &&
                form.data[3].accessControl.competitor.write &&
                whitelist.includes(form.data[3].id)
            "
            name="gender"
            :items="genders"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[3].id}`) }}
              <span v-if="form.data[3].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-select>

          <v-menu
            v-if="
              form.data[4] &&
                form.data[4].accessControl.competitor.write &&
                whitelist.includes(form.data[4].id)
            "
            v-model="showDateOfBirthPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="
                  form.data[4].value
                    ? $d(new Date(form.data[4].value).getTime(), 'shortDate')
                    : null
                "
                name="dateOfBirth"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                @click:prepend="showDateOfBirthPicker = true"
              >
                <template v-slot:label>
                  {{ $t(`competitors.tables.${form.data[4].id}`) }}
                  <span
                    v-if="form.data[4].validation.required"
                    class="error--text"
                    >*</span
                  >
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              ref="dateOfBirthPicker"
              v-model="form.data[4].value"
              no-title
              show-current
              first-day-of-week="1"
              :max="new Date().toISOString().substr(0, 10)"
              @input="showDateOfBirthPicker = false"
            ></v-date-picker>
          </v-menu>

          <v-autocomplete
            v-if="
              form.data[5] &&
                form.data[5].accessControl.competitor.write &&
                whitelist.includes(form.data[5].id)
            "
            name="nation"
            :items="getItemsByLang"
            auto-select-first
            autocomplete="new-password"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[5].id}`) }}
              <span v-if="form.data[5].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-autocomplete>

          <v-text-field
            v-if="
              form.data[6] &&
                form.data[6].accessControl.competitor.write &&
                whitelist.includes(form.data[6].id)
            "
            name="lastName"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[6].id}`) }}
              <span v-if="form.data[6].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-text-field
            v-if="
              form.data[7] &&
                form.data[7].accessControl.competitor.write &&
                whitelist.includes(form.data[7].id)
            "
            name="lastName"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[7].id}`) }}
              <span v-if="form.data[7].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-text-field
            v-if="
              form.data[8] &&
                form.data[8].accessControl.competitor.write &&
                whitelist.includes(form.data[8].id)
            "
            name="class"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[8].id}`) }}
              <span v-if="form.data[8].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-text-field
            v-if="
              form.data[9] &&
                form.data[9].accessControl.competitor.write &&
                whitelist.includes(form.data[9].id)
            "
            name="class"
          >
            <template v-slot:label>
              {{ $t(`competitors.tables.${form.data[9].id}`) }}
              <span v-if="form.data[9].validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-text-field
            v-for="field in customFields"
            :key="field.id"
            :label="field.view.label"
          >
            <template v-slot:label>
              {{ field.view.label }}
              <span v-if="field.validation.required" class="error--text"
                >*</span
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import cuid from "cuid";
import whitelist from "@/mixins/whitelist";

export default {
  name: "FormRegistration",
  props: {
    wizardData: {
      type: Object,
      required: true
    },
    currentStepNumber: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      stepNumber: 4,
      form: {
        data: []
      },
      showDateOfBirthPicker: false
    };
  },
  validations() {
    return {
      form: {
        data: {
          $each: {
            view: {
              label: { required }
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      getItemsByLang: "nations/getItemsByLang"
    }),
    basicFields() {
      return this.form.data.filter(el => this.whitelist.includes(el.id));
    },
    customFields() {
      return this.form.data.slice(
        this.basicFields.length,
        this.form.data.length
      );
    },
    genders() {
      return [
        {
          value: "MALE",
          text: this.$i18n.t("forms.general.genders.male")
        },
        {
          value: "FEMALE",
          text: this.$i18n.t("forms.general.genders.female")
        }
      ];
    },
    formats() {
      return [
        {
          value: "DEFAULT",
          text: this.$i18n.t("forms.event.formats.default")
        },
        {
          value: "UPPERCASE",
          text: this.$i18n.t("forms.event.formats.uppercase")
        },
        {
          value: "SHORT",
          text: this.$i18n.t("forms.event.formats.short")
        }
      ];
    }
  },
  watch: {
    showDateOfBirthPicker(newShowDateOfBirthPicker, oldShowDateOfBirthPicker) {
      newShowDateOfBirthPicker &&
        setTimeout(() => (this.$refs.dateOfBirthPicker.activePicker = "YEAR"));
    },
    currentStepNumber(newCurrentStepNumber, oldCurrentStepNumber) {
      if (newCurrentStepNumber && newCurrentStepNumber === this.stepNumber) {
        this.assignData();
      }
    }
  },
  mixins: [whitelist],
  methods: {
    customFieldNameErrors(index) {
      const errors = [];
      if (!this.$v.form.data.$each.$iter[index].view.label.$dirty)
        return errors;
      !this.$v.form.data.$each.$iter[index].view.label.required &&
        errors.push(
          this.$i18n.t("forms.event.errors.registrationFieldName.required")
        );

      return errors;
    },
    assignData() {
      Object.assign(this.form, this.wizardData.registration);
    },
    submit() {
      this.$v.$touch();

      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve({
            step: "registration",
            form: this.form
          });
        } else {
          reject({
            step: "registration",
            form: this.form
          });
        }
      });
    },
    getCountryItem(item) {
      return this.$i18n.t(`countries.${item.id}`);
    },
    addRegistrationField() {
      const field = {
        id: cuid(),
        type: "STRING",
        view: {
          label: null
        },
        validation: {
          required: false
        },
        accessControl: {
          competitor: {
            read: true,
            write: true
          },
          owner: {
            read: true,
            write: true
          },
          other: {
            read: false,
            write: false
          }
        },
        settings: {
          classDivision: false
        }
      };

      this.form.data.push(field);
      this.$nextTick(() => this.$refs[`field-${field.id}`][0].focus());
      this.$v.form.$touch();
    },
    removeRegistrationField(index) {
      this.form.data.splice(index, 1);
      this.$v.form.$touch();
    },
    moveRegistrationFieldUp(index) {
      const fields = this.moveItem(this.form.data, index, index - 1);
      this.form.data = fields;
      this.$v.form.$touch();
    },
    moveRegistrationFieldDown(index) {
      const fields = this.moveItem(this.form.data, index, index + 1);
      this.form.data = fields;
      this.$v.form.$touch();
    },
    moveItem(items, oldIndex, newIndex) {
      const itemRemovedArray = [
        ...items.slice(0, oldIndex),
        ...items.slice(oldIndex + 1, items.length)
      ];
      return [
        ...itemRemovedArray.slice(0, newIndex),
        items[oldIndex],
        ...itemRemovedArray.slice(newIndex, itemRemovedArray.length)
      ];
    },
    checkFieldVisibility(index) {
      const field = this.form.data[index];

      if (field.accessControl.competitor.write) {
        if (field.id === "class") {
          this.form.data[index].settings.classDivision = true;
        }
      } else {
        if (field.id !== "startNumber") {
          this.form.data[index].accessControl.other.read = false;
        }
        this.form.data[index].validation.required = false;
        this.form.data[index].settings.classDivision = false;
      }
    }
  },
  created() {
    this.assignData();
  },
  activated() {
    this.assignData();
  }
};
</script>
