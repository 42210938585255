<template>
  <v-form @submit.prevent="submitForm">
    <div class="d-flex justify-space-between align-baseline">
      <v-radio-group
        :label="$t('forms.deviceSync.synchronizationConfiguration')"
        v-model="form.sync"
        :readonly="loading"
      >
        <v-radio
          v-for="(syncOption, index) in syncOptions"
          :key="syncOption.value"
          :label="syncOption.text"
          :value="syncOption.value"
          :class="index === 0 ? 'mt-2' : ''"
        ></v-radio>
      </v-radio-group>
      <v-btn
        type="submit"
        color="success"
        class="ml-8"
        elevation="2"
        :loading="loading"
        :fab="$vuetify.breakpoint.name === 'xs'"
        :small="$vuetify.breakpoint.name === 'xs'"
      >
        {{ $vuetify.breakpoint.smAndUp ? $t("forms.deviceSync.submit") : ""
        }}<v-icon :right="$vuetify.breakpoint.smAndUp">mdi-content-save</v-icon>
      </v-btn>
    </div>
    <v-alert v-if="error" class="mt-4 mb-8" dense outlined type="error">
      {{ $t(error) }}
    </v-alert>
    <v-alert
      v-else-if="success"
      class="mt-4 mb-8"
      dense
      outlined
      type="success"
    >
      {{ $t(success) }}
    </v-alert>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PanelDevicesEditSync",
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        sync: this.device.deviceComponents.TimingInfo.syncStrategy ? this.device.deviceComponents.TimingInfo.syncStrategy : "GPS"
      },
      syncOptions: [
        {
          text: this.$i18n.t("forms.deviceSync.gps"),
          value: "GPS"
        },
        {
          text: this.$i18n.t("forms.deviceSync.server"),
          value: "SERVER"
        }
      ],
      loading: false,
      success: null,
      error: null
    };
  },
  validations: {
    form: {
      sync: { required }
    }
  },
  computed: {
    syncErrors() {
      const errors = [];
      if (!this.$v.form.sync.$dirty) return errors;
      !this.$v.form.sync.required && errors.push(this.$i18n.t("forms.deviceSync.errors.sync.required"));
      return errors;
    }
  },
  methods: {
    ...mapActions({
      updateItemSyncConfig: "devices/updateItemSyncConfig"
    }),
    initData() {
      this.form.sync = this.device.deviceComponents.TimingInfo.syncStrategy ? this.device.deviceComponents.TimingInfo.syncStrategy : "GPS";
    },
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    submitForm() {
      this.$v.form.$touch();
      this.success = null;
      this.error = null;

      if (!this.$v.form.$invalid && !this.loading) {
        this.loading = true;

        const payload = {
          deviceId: this.device.id,
          data: {
            sync: this.form.sync
          }
        };

        this.updateItemSyncConfig(payload)
          .then(response => {
            this.loading = false;
            this.success = "forms.deviceSync.success";
          })
          .catch(response => {
            this.loading = false;

            switch (response.data.status) {
              case -2001:
                this.error = "errors.device.updateTimeout";
                break;
              case -2008:
                this.error = "errors.device.communicationFailed";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
          });
      }
    }
  }
};
</script>
