<template>
  <v-dialog v-model="showDialog" width="700">
    <template v-slot:activator="{ on }">
      <v-chip class="device ma-1" :color="getChannel(item).color" outlined pill>
        <v-avatar
          @click="$emit('channelChanged', id, item, index)"
          class="channel"
          :color="getChannel(item).color"
          left
        >
          <span class="white--text">
            {{ getChannel(item).name }}
          </span>
        </v-avatar>

        <span class="label" v-on="on">
          {{ label }}
        </span>

        <v-icon
          @click="$emit('removedDevice', index)"
          class="remove"
          small
          right
        >
          mdi-close
        </v-icon>
      </v-chip>
    </template>

    <PanelDevicesEdit :id="item.device.id" close @closed="showDialog = false" />
  </v-dialog>
</template>

<script>
/* eslint-disable */

import PanelDevicesEdit from "@/components/PanelDevicesEdit";
import deviceTypes from "@/utils/deviceTypes";

export default {
  name: "PanelDevicesEditDialog",
  props: {
    id: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    PanelDevicesEdit
  },
  data() {
    return {
      showDialog: false
    };
  },
  computed: {
    label() {
      if (this.item.device.deviceComponents.AppSettings.name) {
        return `${this.item.device.id} - ${this.item.device.deviceComponents.AppSettings.name}` 
      }

      return this.item.device.id
    }
  },
  methods: {
    getChannel(item) {
      return deviceTypes[item.device.type].channels[item.channelIndex];
    }
  }
};
</script>

<style scoped>
.device {
  overflow: visible;
}
.label {
  cursor: pointer;
  user-select: none;
}
.channel {
  cursor: pointer;
  user-select: none;
  transition: all 0.15s ease-in-out;
}
.channel:hover {
  transform: scale(1.1);
}
.remove {
  color: black;
}
</style>
